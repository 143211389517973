import { Button, Form, InputGroup } from 'react-bootstrap';
import React, { useState, useEffect } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

import './PasswordInput.scss';

const PasswordInput = ({
  placeholder,
  password,
  setPassword,
  isInvalid,
  errorMessage
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [key, setKey] = useState(0); // Key for resetting input field

  // Reset input field when password prop changes to an empty string
  useEffect(() => {
    if (password === '') {
      setKey((prevKey) => prevKey + 1);
    }
  }, [password]);

  return (
    <InputGroup>
      <Form.Control
        placeholder={placeholder}
        key={key} // Reset input field when key changes
        type={showPassword ? 'text' : 'password'}
        required
        onChange={(e) => setPassword(e.target.value)}
        isInvalid={isInvalid}
      />
      <Button
        className='password-toggle-btn'
        onClick={() => setShowPassword(!showPassword)}
      >
        {showPassword ? (
          <FontAwesomeIcon icon={faEyeSlash} />
        ) : (
          <FontAwesomeIcon icon={faEye} />
        )}
      </Button>
      {isInvalid && (
        <Form.Control.Feedback type='invalid' style={{ display: 'block' }}>
          {errorMessage}
        </Form.Control.Feedback>
      )}
    </InputGroup>
  );
};

export default PasswordInput;
