import LoginSignupModal from './LoginSignupModal';
import Container from 'react-bootstrap/Container';
import { Nav, NavDropdown, Navbar, Offcanvas } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AiOutlineMenu } from 'react-icons/ai';

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import config from '../config';
import basketIcon from '../assets/icons/basket.png';
import useLogout from '../hooks/useLogout'; // Adjust the path as necessary

function Header() {
  const navigate = useNavigate();
  const handleLogout = useLogout(navigate);
  const cartItems = useSelector((state) => state.cart.items);

  const loggedIn = useSelector((state) => state.user.loggedIn);

  const [showOffcanvas, setShowOffcanvas] = useState(false);

  const handleClose = () => setShowOffcanvas(false);
  const handleShow = () => setShowOffcanvas(true);

  // Calculate total number of items in the cart
  const totalItems = cartItems.reduce(
    (total, item) => total + item.quantity,
    0
  );

  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    // Initial check for mobile view
    setIsMobileView(window.innerWidth <= 768);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <Navbar key='md' expand='md' className='bg-body-tertiary'>
      <Container>
        <Navbar.Toggle
          aria-controls='responsive-navbar-nav'
          onClick={handleShow}
        >
          <AiOutlineMenu />
        </Navbar.Toggle>
        <Navbar.Brand as='a' href='/' className='navbar-brand-custom mr-auto'>
          <img
            alt='Logo'
            src={`${process.env.PUBLIC_URL}/${config.logoFilename}`}
            className='d-inline-block'
          />
          <p>
            NACHHALTIG.
            <br />
            GRUSSKARTEN.
            <br />
            ZU JEDEM ANLASS.
          </p>
        </Navbar.Brand>

        <Navbar.Offcanvas
          id='offcanvasNavbar-expand-md'
          aria-labelledby='offcanvasNavbarLabel-expand-md'
          placement='start'
          show={showOffcanvas}
          onHide={handleClose}
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title id='offcanvasNavbarLabel-expand-md'>
              Menü
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Nav className='justify-content-end flex-grow-1 pe-3'>
              <Nav.Link
                className='navbar-link-custom'
                as={Link}
                onClick={handleClose}
                to='/shop'
              >
                SHOP
              </Nav.Link>
              {loggedIn ? (
                <>
                  <NavDropdown
                    title='MEIN KONTO'
                    className='navbar-link-custom'
                    // title={
                    //   <img src={userIcon} alt='Mein Konto' className='nav-icon' />
                    // }
                    // title={`Willkommen${
                    //   user && user.first_name ? ' ' + user.first_name : ''
                    // }!`}
                  >
                    <NavDropdown.Item
                      as={Link}
                      to='/orders'
                      onClick={handleClose}
                    >
                      Bestellungen
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      as={Link}
                      to='/profile'
                      onClick={handleClose}
                    >
                      Profil
                    </NavDropdown.Item>

                    {isMobileView ? (
                      <></>
                    ) : (
                      <>
                        <NavDropdown.Divider />
                        <NavDropdown.Item onClick={handleLogout}>
                          Logout
                        </NavDropdown.Item>
                      </>
                    )}
                  </NavDropdown>
                  {isMobileView ? (
                    <Nav.Link
                      className='navbar-link-custom'
                      as={Link}
                      to='/logout'
                      onClick={handleClose}
                    >
                      LOGOUT
                    </Nav.Link>
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <></>
              )}
            </Nav>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
        {!loggedIn ? <LoginSignupModal /> : <></>}

        <Nav.Link as={Link} to='/cart'>
          <span className={totalItems > 0 ? 'dot' : ''}>
            <img src={basketIcon} alt='Warenkorb' className='nav-icon' />
          </span>
        </Nav.Link>
      </Container>
    </Navbar>
  );
}

export default Header;
