import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Nav } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';

const ProfilePageLayout = ({ children }) => {
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 768);
  const location = useLocation();

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    // Initial check for mobile view
    setIsMobileView(window.innerWidth <= 768);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <Container>
      {isMobileView ? (
        <>
          <Row>
            <Col>
              <Nav
                variant='tabs'
                activeKey={location.pathname}
                className='mb-3'
              >
                <Nav.Item>
                  <Nav.Link as={Link} to='/orders' eventKey='/orders'>
                    Bestellungen
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link as={Link} to='/profile' eventKey='/profile'>
                    Profil
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
          </Row>
          <Row>
            <Col>{children}</Col>
          </Row>
        </>
      ) : (
        <Row>
          {/* Sidebar */}
          <Col md={3} className='bg-light'>
            <h2 className='mt-2'>Mein Konto</h2>
            <Nav defaultActiveKey='/home' className='flex-column'>
              <Nav.Link as={Link} to='/orders'>
                Bestellungen
              </Nav.Link>
              <Nav.Link as={Link} to='/profile'>
                Profil
              </Nav.Link>
              <Nav.Item>
                <hr />
              </Nav.Item>
              <Nav.Link as={Link} to='/logout'>
                Logout
              </Nav.Link>
            </Nav>
          </Col>

          {/* Main Content */}
          <Col md={9} className='p-3'>
            {children}
          </Col>
        </Row>
      )}
    </Container>
  );
};

export default ProfilePageLayout;
