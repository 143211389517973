import React from 'react';
import { Link } from 'react-router-dom';
import { IoMdRefresh } from 'react-icons/io';

const ReloadButton = () => {
  const linkStyle = {
    color: 'darkgrey', // This sets the text/icon color. Adjust as needed.
    cursor: 'pointer', // Ensures the cursor changes to pointer on hover for better UX
    fontSize: '1em' // Increases the icon size to make it more prominent. Adjust as needed.
  };

  const reloadPage = () => {
    window.location.reload();
  };

  return (
    <Link onClick={reloadPage} style={linkStyle}>
      <IoMdRefresh /> Neu Laden
    </Link>
  );
};

export default ReloadButton;
