import axiosInstance from '../axiosConfig';

const fetchProducts = async () => {
  const response = await axiosInstance.get(`/api/product/`);
  return response.data;
};

const fetchProduct = async (productId) => {
  const response = await axiosInstance.get(`/api/product/${productId}`);
  return response.data;
};

const fetchPriceByProduct = async (productId) => {
  const response = await axiosInstance.get(
    `/api/price/by-product/${productId}/`
  );
  return response.data;
};

export { fetchPriceByProduct, fetchProducts, fetchProduct };
