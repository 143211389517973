// Cart.js
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateQuantity, removeItem } from '../redux/cartSlice';
import {
  Alert,
  Col,
  Row,
  Button,
  Form,
  InputGroup,
  Container
} from 'react-bootstrap';
import Table from 'react-bootstrap/Table';

import { useNavigate } from 'react-router-dom';

import { AiFillDelete } from 'react-icons/ai'; // Import the trash bin icon component
import { FaArrowLeft } from 'react-icons/fa';
import axiosInstance from '../axiosConfig';
import { Link } from 'react-router-dom';
import InvoiceForm from './InvoiceForm';

function areAllCurrenciesTheSame(currencies) {
  // Check if the array is empty or contains only one element
  if (currencies.length <= 1) {
    return true;
  }

  // Get the first currency in the list to compare with others
  const firstCurrency = currencies[0];

  // Check if every other currency in the list matches the first one
  return currencies.every((currency) => currency === firstCurrency);
}

function getCurrenciesFromItems(items) {
  return items.map((item) => item.currency);
}

const Cart = () => {
  const loggedIn = useSelector((state) => state.user.loggedIn);
  const is_email_confirmed =
    useSelector((state) => state.user.user?.is_email_confirmed) || false;
  const [invoiceToggle, setInvoiceToggle] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [allSameCurrency, setAllSameCurrency] = useState(false);
  const dispatch = useDispatch();
  const items = useSelector((state) => state.cart.items);

  const handleUpdateQuantity = (id, newQuantity) => {
    dispatch(updateQuantity({ id, quantity: newQuantity }));
  };

  const handleRemoveFromCart = (item) => {
    dispatch(removeItem(item));
  };

  useEffect(() => {
    const currencies = getCurrenciesFromItems(items);
    const allSameCurrency = areAllCurrenciesTheSame(currencies);
    setAllSameCurrency(allSameCurrency);
    if (!allSameCurrency) {
      setErrorMessage(
        'Verschiedene Währungen im Warenkorb. Bitte wählen Sie nur Artikel einer Währung pro Bestellvorgang.'
      );
    } else {
      setErrorMessage(''); // Clear error message if all currencies are the same
    }
  }, [items]); // Depend on `items` to re-run this effect

  const handleCheckout = (e) => {
    e.preventDefault(); // Prevent the default behavior of the click event

    axiosInstance
      .post('/api/create-checkout-session/', {
        line_items: items.map((item) => ({
          price: item.price.id,
          quantity: item.quantity
        }))
      })
      .then((response) => {
        // Extract the checkout session URL from the response data
        const { url } = response.data;
        // Redirect the user to the checkout session URL
        window.location.href = url;
      })
      .catch((error) => {
        setErrorMessage(
          'Checkout-Session konnte nicht erstellt werden. Bitte probieren Sie es später nocheinmal oder kontaktieren unseren Support.'
        );
      });
  };

  const totalPrice = items.reduce((acc, item) => {
    const price_quantity =
      item.price.transform_quantity && item.price.transform_quantity.divide_by
        ? item.price.transform_quantity.divide_by
        : 1;

    const roundedQuantity = Math.ceil(item.quantity / price_quantity);
    const price_total = roundedQuantity * item.price.unit_amount;
    return acc + price_total;
  }, 0);

  const getSubTotalPrice = (item) => {
    const price_quantity =
      item.price.transform_quantity && item.price.transform_quantity.divide_by
        ? item.price.transform_quantity.divide_by
        : 1;

    const roundedQuantity = Math.ceil(item.quantity / price_quantity);
    const price_total = roundedQuantity * item.price.unit_amount;
    return formatPrice(price_total);
  };

  const formatPrice = (price) => {
    return (price / 100).toFixed(2);
  };

  const currencyTranslations = {
    eur: '€'
    // usd: '$' currently only € is supported due to Versandoption
  };

  const getPrice = (item) => {
    const price_quantity =
      item.price.transform_quantity && item.price.transform_quantity.divide_by
        ? item.price.transform_quantity.divide_by
        : 1;

    const price = formatPrice(item.price.unit_amount);
    const currency = currencyTranslations[item.price.currency];

    if (price_quantity === 1) {
      return `${price} ${currency}`;
    } else {
      return `${price} ${currency} pro ${price_quantity} Stück`;
    }
  };

  // Step 2: Toggle the state variable
  const toggleInvoice = () => {
    if (!loggedIn) {
      setErrorMessage(
        'Rechnung kann nur von registrierten Benutzern erstellt werden. Bitte loggen Sie sich ein oder registrieren Sie sich.'
      );
    } else if (!is_email_confirmed) {
      setErrorMessage(
        'Bitte bestätige zuerst noch deine E-Mail-Adresse, um mit der Bestellung per Rechnung fortzufahren.'
      );
    } else {
      setInvoiceToggle((prevState) => !prevState);
      setErrorMessage(''); // Clear any previous error message
    }
  };

  const navigate = useNavigate();
  const handleRedirect = () => {
    navigate('/shop');
  };

  const handleClose = () => {
    setErrorMessage('');
  };

  const handleSuccessClose = () => {
    setSuccessMessage('');
  };

  return (
    <Container>
      <h2>Warenkorb</h2>
      <Button variant='secondary' onClick={handleRedirect}>
        <FaArrowLeft /> Zu den Produkten
      </Button>
      {successMessage && (
        <div
          className='alert alert-success alert-dismissible fade show mt-2'
          role='alert'
        >
          <div dangerouslySetInnerHTML={{ __html: successMessage }} />
          <button
            type='button'
            className='btn-close'
            aria-label='Close'
            onClick={() => handleSuccessClose()}
          ></button>
        </div>
      )}
      {errorMessage && (
        <Alert
          className='mt-2'
          variant='danger'
          onClose={handleClose}
          dismissible
        >
          {errorMessage}
        </Alert>
      )}
      {items.length === 0 ? (
        <Alert className='mt-2' variant='info'>
          Der Warenkorb ist leer.
        </Alert>
      ) : (
        <>
          <div className='table-responsive'>
            <Table className='mt-2' striped bordered hover>
              <thead>
                <tr>
                  <th>Produkt</th>
                  <th>Preis</th>
                  <th>Menge</th>
                  <th>Subtotal</th>
                </tr>
              </thead>
              <tbody>
                {items.map((item) => (
                  <tr key={item.id} style={{ verticalAlign: 'middle' }}>
                    <td
                      style={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        maxWidth: '50px'
                      }}
                    >
                      <Link to={`/product/${item.id}`}>
                        <img
                          src={item.imageUrl}
                          alt={item.name}
                          style={{ width: '50px', height: '50px' }}
                        />
                        <br />
                        {item.name}
                      </Link>
                    </td>
                    <td>{getPrice(item)}</td>
                    <td>
                      <InputGroup className='custom-quantity-input'>
                        <Form.Control
                          type='number'
                          value={item.quantity}
                          onChange={(e) =>
                            handleUpdateQuantity(
                              item.id,
                              parseInt(e.target.value, 10)
                            )
                          }
                          min='1'
                          max='200'
                          step='1'
                          inputMode='numeric'
                        />
                        <Button
                          variant='secondary'
                          onClick={() => handleRemoveFromCart({ id: item.id })}
                        >
                          <AiFillDelete />
                        </Button>
                      </InputGroup>
                    </td>
                    <td>
                      {getSubTotalPrice(item)}{' '}
                      {currencyTranslations[item.price.currency]}
                    </td>
                  </tr>
                ))}
                {/* Summary row */}
                <tr>
                  <td colSpan='3' style={{ textAlign: 'right' }}>
                    Versand
                  </td>
                  <td>4,90 €</td>
                </tr>
                <tr>
                  <td colSpan='3' style={{ textAlign: 'right' }}>
                    <strong>Gesamtpreis</strong>
                  </td>
                  <td>
                    <strong>
                      {allSameCurrency ? (
                        <>
                          {formatPrice(totalPrice + 490)}{' '}
                          {currencyTranslations[items[0].price.currency]}
                        </>
                      ) : (
                        <div>Keine Berechnung</div>
                      )}
                    </strong>
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
          <Row className='mt-3'>
            <Col md={4}>
              <Button
                variant='primary'
                onClick={handleCheckout}
                className='w-100'
              >
                Jetzt bezahlen
              </Button>
              <p className='mt-3'>
                Ihre Zahlung wird sicher über <b>Stripe</b> abgewickelt.
              </p>
            </Col>
            <Col md={4}>
              <Button
                variant={
                  loggedIn ? (invoiceToggle ? 'light' : 'primary') : 'light'
                }
                className='w-100 mb-3'
                onClick={toggleInvoice}
              >
                {invoiceToggle ? 'Abbrechen' : 'Später bezahlen mit Rechnung'}
              </Button>
              {invoiceToggle ? (
                <InvoiceForm
                  items={items}
                  setErrorMessage={setErrorMessage}
                  setSuccessMessage={setSuccessMessage}
                />
              ) : (
                <></>
              )}
            </Col>
          </Row>
        </>
      )}
    </Container>
  );
};

export default Cart;
