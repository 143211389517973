import React from 'react';
import './ProductCardZoom.css'; // Import the CSS file
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ProductActions from './ProductActions'; // Import the new component
import config from '../config';

const ProductCardZoom = ({ product }) => {
  const { id, name, images } = product;

  const firstImageUrl =
    images && images.length > 0 ? images[0] : config.imageNotFound;

  const handleImageError = (e) => {
    e.target.src = config.imageNotFound;
  };

  return (
    <Card className='productCardZoom'>
      <div className='productImageContainer'>
        <Link to={`/product/${id}`}>
          <Card.Img
            variant='top'
            src={firstImageUrl}
            alt={name}
            className='productImage'
            onError={handleImageError}
          />
        </Link>
      </div>
      <Card.Body className='productInfo'>
        <Card.Title>
          <h5>{name}</h5>
        </Card.Title>
        <Card.Text as='div'>
          <div className='d-flex flex-column'>
            <ProductActions product={product} />
          </div>
        </Card.Text>
      </Card.Body>
    </Card>
  );
};

export default ProductCardZoom;
