// cartSelectors.js
import { createSelector } from 'reselect';

// Select the cart state
const selectCart = (state) => state.cart;

// Select the items array from the cart state
export const selectCartItems = createSelector(
  [selectCart],
  (cart) => cart.items
);

// Selector function to get the quantity of a specific item
export const selectItemQuantity = (itemId) =>
  createSelector([selectCartItems], (items) => {
    const item = items.find((item) => item.id === itemId);
    return item ? item.quantity : 0; // Return item quantity if found, otherwise return 0
  });

export const selectItemPrice = (itemId) =>
  createSelector([selectCartItems], (items) => {
    const item = items.find((item) => item.id === itemId);
    return item ? item.price : 0; // Return item quantity if found, otherwise return 0
  });
