// utils/allowCookies.js
import Cookies from 'js-cookie';

const initializeMatomoTracking = () => {
  window._paq = window._paq || [];
  window._paq.push(['trackPageView']);
  window._paq.push(['enableLinkTracking']);
  //Matomo Tag Manager
  var _mtm = (window._mtm = window._mtm || []);
  _mtm.push({ 'mtm.startTime': new Date().getTime(), event: 'mtm.Start' });
  (function () {
    var d = document,
      g = d.createElement('script'),
      s = d.getElementsByTagName('script')[0];
    g.async = true;
    g.src = process.env.REACT_APP_MATOMO;
    s.parentNode.insertBefore(g, s);
  })();
  // End Matomo Tag Manager
};

const allowCookies = () => {
  Cookies.set('cookieConsent', 'true', { expires: 365 });
  // Add any additional initializations here
  // For example, initializing tracking scripts
  initializeMatomoTracking();
};

export default allowCookies;
