import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import fahrradImg from '../assets/images/fahrrad.png';
import herzImg from '../assets/images/herz.png';
import plastikImg from '../assets/images/plastik.png';
import recyclingImg from '../assets/images/recycling.png';

const MissionIcons = () => {
  return (
    <Container>
      <Row>
        <Col xs={6} lg={3} className='d-flex align-items-end'>
          <img
            src={recyclingImg}
            alt='recycling'
            style={{
              width: '100%',
              maxWidth: '100%',
              height: 'auto'
            }}
          />
        </Col>

        <Col xs={6} lg={3} className='d-flex align-items-end'>
          <img
            src={plastikImg}
            alt='kein plastik'
            style={{
              width: '100%',
              maxWidth: '100%',
              height: 'auto'
            }}
          />
        </Col>
        <Col xs={6} lg={3} className='d-flex align-items-end'>
          <img
            src={herzImg}
            alt='herz'
            style={{
              width: '100%',
              maxWidth: '100%',
              height: 'auto'
            }}
          />
        </Col>
        <Col xs={6} lg={3} className='d-flex align-items-end'>
          <img
            src={fahrradImg}
            alt='fahrrad'
            style={{
              width: '100%',
              maxWidth: '100%',
              height: 'auto'
            }}
          />
        </Col>
        {/* <Col xs={3} lg={2} rowSpan={2}>
          <div className='grid-item'>TOW</div>
        </Col> */}
      </Row>
    </Container>
  );
};

export default MissionIcons;
