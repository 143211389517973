import React from 'react';
import { Carousel, Button, Container } from 'react-bootstrap';
import hero1Image from '../assets/images/hero_1.png';
import hero2Image from '../assets/images/hero_2.png';
import './HeroCarousel.scss'; // Ensure your CSS styles are in place
import { useNavigate } from 'react-router-dom';

const HeroCarousel = () => {
  const navigate = useNavigate();

  const handleShopRedirect = () => {
    navigate('/shop');
  };

  const handleEtsyRedirect = () => {
    window.location.href = 'https://www.etsy.com/de/shop/miapapeteria';
  };

  return (
    <Carousel className='hero-carousel'>
      <Carousel.Item>
        <img
          className='d-block w-100'
          src={hero1Image}
          alt='fuer privatkunden'
        />
        {/* <img className='d-block w-100' src={hero2Image} alt='Second slide' /> */}
        <Carousel.Caption className='carousel-caption-background'>
          <Container className='h-100 d-flex flex-column justify-content-center align-items-center p-4'>
            <h1 className='font-weight-bold'>Willkommen bei miapapeteria!</h1>
            <Button
              variant='primary'
              className='mt-2'
              onClick={handleShopRedirect}
            >
              Zu den Produkten
            </Button>
          </Container>
        </Carousel.Caption>
      </Carousel.Item>

      <Carousel.Item>
        <img
          className='d-block w-100'
          src={hero2Image}
          alt='fuer privatkunden'
        />
        {/* <img className='d-block w-100' src={hero2Image} alt='Second slide' /> */}
        <Carousel.Caption className='carousel-caption-background'>
          <Container className='h-100 d-flex flex-column justify-content-center align-items-center p-4 '>
            <h1 className='font-weight-bold'>
              Du benötigst nur einzelne Karten?
            </h1>
            <Button
              variant='light'
              className='mt-2'
              onClick={handleEtsyRedirect}
            >
              Besuche miapapeteria auf Etsy
            </Button>
          </Container>
        </Carousel.Caption>
      </Carousel.Item>

      {/* <Carousel.Item>
        <img
          className='d-block w-100'
          src='your-image-url-2.jpg'
          alt='Second slide'
        />
        <Carousel.Caption>
          <h3>Second slide label</h3>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
        </Carousel.Caption>
      </Carousel.Item>

      <Carousel.Item>
        <img
          className='d-block w-100'
          src='your-image-url-3.jpg'
          alt='Third slide'
        />
        <Carousel.Caption>
          <h3>Third slide label</h3>
          <p>
            Praesent commodo cursus magna, vel scelerisque nisl consectetur.
          </p>
        </Carousel.Caption>
      </Carousel.Item> */}
    </Carousel>
  );
};

export default HeroCarousel;
