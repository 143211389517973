import React, { useState, useEffect, useCallback, useMemo } from 'react';
import './ProductList.css';
import ProductCardZoom from './ProductCardZoom';
import { useDispatch } from 'react-redux';
import { withRetryOnUnauthorized } from '../utils/withRetryOnUnauthorized';
import { addMessage } from '../redux/messageSlice'; // Adjust path as necessary
import { fetchProducts, fetchPriceByProduct } from '../utils/api';
import { Container, Row, Col } from 'react-bootstrap';

const NewestProductList = ({ max_products }) => {
  const [products, setProducts] = useState([]);
  const dispatch = useDispatch();

  // Memoizing retry-enabled functions. Without useMemo withRetryOnUnauthorized would initiate a new function each time and retrigger the fetch over and over in a dependency loop
  const fetchProductsWithRetry = useMemo(
    () => withRetryOnUnauthorized(fetchProducts),
    []
  );

  const fetchPriceByProductWithRetry = useMemo(
    () => withRetryOnUnauthorized(fetchPriceByProduct),
    []
  );

  const resolvePrices = useCallback(
    async (productsWithoutPrice) => {
      const resolvedProducts = await Promise.all(
        productsWithoutPrice.map(async (product) => {
          const prices = await fetchPriceByProductWithRetry(product.id);
          return { ...product, prices };
        })
      );
      return resolvedProducts;
    },
    [fetchPriceByProductWithRetry]
  );

  const fetchData = useCallback(async () => {
    try {
      // Assuming fetchProductsWithRetry is defined and returns a list of products without prices
      const productsWithoutPrice = await fetchProductsWithRetry();

      // Assuming resolvePrices is a function that takes products and enriches them with prices
      // Ensure resolvePrices is either defined outside of this component or included in useEffect's dependency array
      const resolvedProducts = await resolvePrices(productsWithoutPrice);

      // Sort products by creation date (assuming product.metadata.creationDate exists and is in a comparable format)
      resolvedProducts.sort(
        (a, b) =>
          new Date(b.metadata.creationDate) - new Date(a.metadata.creationDate)
      );

      // If max_products is set, limit the number of products
      const limitedProducts = max_products
        ? resolvedProducts.slice(0, max_products)
        : resolvedProducts;

      setProducts(limitedProducts);
    } catch (error) {
      setProducts([]);
      dispatch(
        addMessage({
          text: 'Fehler beim Laden der Produkte, bitte versuchen Sie es später erneut.',
          messageType: 'danger',
          dismissible: true
        })
      );
      // Handle errors, maybe set an error state, or setProducts to an empty array, etc.
    }
  }, [resolvePrices, fetchProductsWithRetry, max_products, dispatch]);

  useEffect(() => {
    // Call fetchData and then update state with the result
    fetchData();
  }, [fetchData]); // Make sure to include any external dependencies in this array

  return (
    <Container className='productList'>
      <Col md={12}>
        {/* Conditionally render the error message */}
        {products && products.length > 0 ? (
          // Render ProductCardZoom components if products array is not empty
          <Row xs={1} sm={2} md={4} className='card-container'>
            {products.map((product) => (
              <Col key={product.id} className='d-flex'>
                <ProductCardZoom
                  product={product}
                  className='productCardZoom'
                />
              </Col>
            ))}
          </Row>
        ) : (
          // Render PlaceholderComponent if products array is empty
          <p>Lade Produkte..</p>
        )}
      </Col>
    </Container>
  );
};

export default NewestProductList;
