import React from 'react';
import NewestProductList from '../components/NewestProductList';
import HeroCarousel from '../components/HeroCarousel';
import HomepageLayout from '../layouts/HomepageLayout';
import MissionIcons from '../components/MissionIcons';
import AboutSection from '../components/AboutSection';
import { Button } from 'react-bootstrap';
import { AiOutlineArrowRight } from 'react-icons/ai'; // Import the arrow right icon

import { useNavigate } from 'react-router-dom';

const HomePage = () => {
  const navigate = useNavigate();

  const handleShopRedirect = () => {
    navigate('/shop');
  };
  const content = (
    <>
      <MissionIcons />
      <div className='d-flex align-items-center mt-4'>
        <h1 className='me-3'>Neueste Produkte</h1>
        <Button variant='light' onClick={handleShopRedirect}>
          Alle Produkte <AiOutlineArrowRight />
        </Button>
      </div>
      <hr style={{ borderTop: '1px solid gray' }} />
      <NewestProductList max_products={4} />
      <h1>Über mich</h1>
      <hr style={{ borderTop: '1px solid gray' }} />
      <AboutSection></AboutSection>
    </>
  );

  const hero = <HeroCarousel></HeroCarousel>;

  return <HomepageLayout hero={hero} children={content}></HomepageLayout>;
};

export default HomePage;
