import { logoutUser } from './userSlice';
import { persistor } from './store'; // Adjust import path as needed

export const logoutMiddleware = (store) => (next) => (action) => {
  if (action.type === logoutUser.fulfilled.toString()) {
    persistor.purge();
    // Additional cleanup if necessary
  }
  return next(action);
};
