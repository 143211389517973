import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Container, Row, Col, Dropdown } from 'react-bootstrap';
import { FaRegTrashAlt } from 'react-icons/fa';

import {
  addItem,
  removeItem,
  updateQuantity,
  changePrice
} from '../redux/cartSlice';
import { selectItemQuantity, selectItemPrice } from '../redux/cartSelectors';
import { useNavigate } from 'react-router-dom';

const formatAmount = (unitAmount) => {
  const amountInEuros = unitAmount / 100; // Assuming the smallest unit is cents
  return `${amountInEuros.toFixed(2)}`;
};

const ProductActions = ({ product }) => {
  const { id, name, prices, images } = product;
  const [selectedPrice, setSelectedPrice] = useState(
    useSelector(selectItemPrice(id))
  );
  const quantityInCart = useSelector(selectItemQuantity(id));
  const [blink, setBlink] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const delta_quantity = selectedPrice?.transform_quantity?.divide_by || 1;

  useEffect(() => {
    let blinkInterval;
    if (blink) {
      blinkInterval = setInterval(() => {
        setBlink((prevBlink) => !prevBlink);
      }, 500);
    } else {
      clearInterval(blinkInterval);
    }
    return () => clearInterval(blinkInterval);
  }, [blink]);

  const handleChangePrice = (price) => {
    setSelectedPrice(price);
    dispatch(changePrice({ id, price }));
  };

  const handleAddToCart = () => {
    const price_quantity = selectedPrice?.transform_quantity?.divide_by || 1;
    const item = {
      id,
      name,
      price: selectedPrice || prices[0],
      imageUrl: images[0],
      quantity: price_quantity
    };
    if (selectedPrice || prices.length === 1) {
      dispatch(addItem(item));
    } else {
      setBlink(true);
    }
  };

  const handleCheckoutRedirect = () => {
    navigate('/cart');
  };

  const currencyTranslations = {
    eur: '€',
    usd: '$'
  };

  const getCurrencySymbol = (currencyCode) => {
    return currencyTranslations[currencyCode.toLowerCase()] || '?';
  };

  const getToggleText = () => {
    if (prices.length === 1) {
      const pricePerUnit =
        prices[0].transform_quantity && prices[0].transform_quantity.divide_by
          ? `pro ${prices[0].transform_quantity.divide_by} Stück `
          : '1 Stück ';

      const amount = formatAmount(prices[0].unit_amount);
      const currencySymbol = getCurrencySymbol(prices[0].currency);

      return `${pricePerUnit}(${amount} ${currencySymbol})`;
    }

    if (!selectedPrice) {
      return 'Option auswählen';
    }

    const pricePerUnit = selectedPrice?.transform_quantity?.divide_by
      ? `pro ${selectedPrice.transform_quantity.divide_by} Stück`
      : '1 Stück';
    const amount = formatAmount(selectedPrice.unit_amount);
    const currencySymbol = getCurrencySymbol(selectedPrice.currency);
    return `${pricePerUnit} (${amount} ${currencySymbol})`;
  };

  const increment = () => {
    const price_quantity = selectedPrice?.transform_quantity?.divide_by || 1;
    dispatch(updateQuantity({ id, quantity: quantityInCart + price_quantity }));
  };

  const decrement = () => {
    const price_quantity = selectedPrice?.transform_quantity?.divide_by || 1;
    const newQuantity = quantityInCart - price_quantity;
    if (newQuantity > 0) {
      dispatch(updateQuantity({ id, quantity: newQuantity }));
    } else {
      dispatch(removeItem({ id }));
    }
  };

  return (
    <div className='product-actions'>
      {prices.length === 1 && (
        <div style={{ textAlign: 'center' }}>
          {prices[0].transform_quantity?.divide_by
            ? `pro ${prices[0].transform_quantity.divide_by} Stück`
            : '1 Stück'}{' '}
          ({formatAmount(prices[0].unit_amount)}{' '}
          {getCurrencySymbol(prices[0].currency)})
        </div>
      )}
      {prices.length >= 2 && (
        <Dropdown>
          <Dropdown.Toggle
            variant='light'
            id='dropdown-basic'
            style={{ width: '100%', backgroundColor: blink ? '#d88890' : '' }}
          >
            {getToggleText(selectedPrice)}
          </Dropdown.Toggle>

          <Dropdown.Menu style={{ width: '100%' }}>
            {prices.map((price, index) => (
              <Dropdown.Item
                key={index}
                onClick={() => handleChangePrice(price)}
              >
                {price.transform_quantity?.divide_by
                  ? `pro ${price.transform_quantity.divide_by} Stück`
                  : '1 Stück'}{' '}
                ({formatAmount(price.unit_amount)}{' '}
                {getCurrencySymbol(price.currency)})
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      )}
      {quantityInCart > 0 && (
        <Container className='mt-3 mb-3'>
          <Row className='justify-content-center'>
            <Col xs='auto'>
              <Button
                variant='light'
                className='round-button'
                onClick={decrement}
                style={{ borderRadius: '50%', padding: '0.5rem' }}
              >
                {quantityInCart - delta_quantity <= 0 ? (
                  <FaRegTrashAlt color='black' size='1em' />
                ) : (
                  '-'
                )}
              </Button>
            </Col>
            <Col className='d-flex align-items-center' xs='auto'>
              {quantityInCart}
            </Col>
            <Col xs='auto'>
              <Button
                className='round-button'
                variant='light'
                onClick={increment}
                style={{ padding: '0.5rem 1rem' }}
              >
                +
              </Button>
            </Col>
          </Row>
        </Container>
      )}
      {quantityInCart > 0 ? (
        <Button
          variant='secondary'
          className='mt-2'
          onClick={handleCheckoutRedirect}
          style={{ width: '100%' }}
        >
          Zum Warenkorb
        </Button>
      ) : (
        <Button
          variant='primary'
          onClick={handleAddToCart}
          className='mt-2'
          style={{ width: '100%' }}
        >
          In den Warenkorb
        </Button>
      )}
    </div>
  );
};

export default ProductActions;
