import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import config from '../config';

const ErrorPage = () => {
  const navigate = useNavigate();

  const handleRedirect = () => {
    navigate('/');
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        textAlign: 'center',
        padding: '20px'
      }}
    >
      <img
        src={config.somethingWentWrong}
        alt='something went wrong'
        style={{
          maxWidth: '100%',
          height: 'auto',
          maxHeight: '500px',
          marginBottom: '20px'
        }}
      />
      <Button variant='secondary' onClick={handleRedirect}>
        Zurück zur Startseite
      </Button>
    </div>
  );
};

export default ErrorPage;
