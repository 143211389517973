import { Container, Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

function Footer() {
  const navigate = useNavigate();
  return (
    <footer className='mt-auto py-3 bg-light'>
      <Container>
        <Row className='align-items-center'>
          {/* Social Media Links */}
          <Col sm={4} className='text-center text-sm-left mb-2 mb-sm-0'>
            Finde uns auf
            <a
              href='https://www.etsy.com/de/shop/miapapeteria'
              className='mx-2'
              aria-label='Etsy'
            >
              {/* <FaEtsy /> */}
              Etsy
            </a>
            {/* <a href='https://twitter.com' className='mx-2' aria-label='Twitter'>
              <FaTwitter />
            </a>
            <a
              href='https://instagram.com'
              className='mx-2'
              aria-label='Instagram'
            >
              <FaInstagram />
            </a> */}
          </Col>

          {/* Data Privacy and Impressum */}
          <Col sm={8} className='text-center text-sm-right'>
            <button
              onClick={() => navigate('/impressum')}
              className='btn btn-link mx-2'
              style={{ textDecoration: 'none' }}
            >
              Impressum
            </button>
            <button
              onClick={() => navigate('/agb')}
              className='btn btn-link mx-2'
              style={{ textDecoration: 'none' }}
            >
              AGB
            </button>
            <button
              onClick={() => navigate('/data-privacy')}
              className='btn btn-link mx-2'
              style={{ textDecoration: 'none' }}
            >
              Datenschutz
            </button>
            <button
              onClick={() => navigate('/cookie-settings')}
              className='btn btn-link mx-2'
              style={{ textDecoration: 'none' }}
            >
              Cookie-Einstellungen
            </button>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default Footer;
