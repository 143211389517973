import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Form, Button, Alert, Modal } from 'react-bootstrap';
import { deleteAccount, logoutUser } from '../redux/userSlice'; // Adjust the import path as necessary
import { useNavigate } from 'react-router-dom';
import PasswordInput from './PasswordInput';

const DeleteAccount = () => {
  const [showForm, setShowForm] = useState(false); // State to control form display
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleDeleteClick = () => setShowForm(true); // Handler to show the form

  const handleFormSubmit = (e) => {
    e.preventDefault();
    dispatch(deleteAccount(password))
      .unwrap()
      .then(() => {
        dispatch(logoutUser()).then(() => navigate('/'));
      })
      .catch((error) => {
        // Assuming error handling logic based on the action's error structure
        setError(
          error.message ||
            'Fehler beim Löschen Ihres Accounts. Prüfen Sie ihr Passwort.'
        );
      });
  };

  return (
    <>
      <Button variant='danger' onClick={handleDeleteClick}>
        Account löschen
      </Button>

      <Modal show={showForm} onHide={() => setShowForm(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Bestätige die Account-Löschung</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {error && <Alert variant='danger'>{error}</Alert>}
          <Form onSubmit={handleFormSubmit}>
            <Form.Group className='mb-3' controlId='formBasicPassword'>
              <Form.Label>Passwort</Form.Label>
              <PasswordInput
                placeholder='Bestätigen Sie ihr Passwort'
                password={password}
                setPassword={setPassword}
              ></PasswordInput>
              {/* <Form.Control
                type='password'
                placeholder='Bestätigen Sie ihr Passwort'
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              /> */}
            </Form.Group>
            <Button variant='danger' type='submit'>
              Konto löschen
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default DeleteAccount;
