import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Alert } from 'react-bootstrap';
import { removeMessage } from '../redux/messageSlice';

import { Container, Row, Col } from 'react-bootstrap';

const MessageComponent = () => {
  const messages = useSelector((state) => state.message.messages);

  const dispatch = useDispatch();

  // Example function to clear the message
  const handleDismiss = (id) => {
    dispatch(removeMessage({ id }));
  };

  return (
    messages.length > 0 && (
      <Container>
        <Row>
          <Col className='p-3'>
            {messages.map((message) => (
              <Alert
                key={message.id}
                variant={message.messageType}
                onClose={() => handleDismiss(message.id)}
                dismissible={message.dismissible}
              >
                {message.text}
              </Alert>
            ))}
          </Col>
        </Row>
      </Container>
    )
  );
};

export default MessageComponent;
