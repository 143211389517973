// messageSlice.js
import { createSlice } from '@reduxjs/toolkit';

export const messageSlice = createSlice({
  name: 'message',
  initialState: {
    messages: [] // Changed to an array to hold multiple messages
  },
  reducers: {
    addMessage: (state, action) => {
      state.messages.push({
        id: new Date().toISOString(),
        text: action.payload.text,
        messageType: action.payload.messageType || 'info',
        dismissible: action.payload.dismissible || false
      });
    },
    removeMessage: (state, action) => {
      state.messages = state.messages.filter(
        (message) => message.id !== action.payload.id
      );
    },
    clearMessages: (state) => {
      state.messages = [];
    }
  }
});

export const { addMessage, removeMessage, clearMessages } =
  messageSlice.actions;

export default messageSlice.reducer;
