export function handleErrorResponse(error, setErrorMessages) {
  const errorMessages = [];
  if (error.response) {
    const { status, data } = error.response || {};

    if (status === 400 && data) {
      // Function to add new error messages

      for (const key in data) {
        if (data.hasOwnProperty(key)) {
          data[key].forEach((errorMessage) => {
            errorMessages.push(errorMessage);
          });
        }
      }

      //setErrorMessages((prevErrors) => [...prevErrors, ...errorMessages]);
      setErrorMessages(() => [...errorMessages]);
      return; //avoid printing of generic error message
    }
  }
  // If there are no specific error messages, set a generic error message
  setErrorMessages([
    'Es tut uns leid, es bestehen Verbindungsprobeme zum Server. Bitte versuchen Sie es später erneut.'
  ]);
}
