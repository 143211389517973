import React, { useState, useEffect, useRef } from 'react';
import { Image, Container, Carousel, Row, Col } from 'react-bootstrap';
import './ProductDetail.scss'; // Ensure your CSS styles are in place
import ProductActions from './ProductActions'; // Import the ProductActions component
import config from '../config';
import klappCardImg from '../assets/images/klappkarte.png';

const formatCategoryString = (inputString) => {
  // Remove all spaces
  const noSpaces = inputString.replace(/\s+/g, '');

  // Split by comma
  const wordsArray = noSpaces.split(',');

  // Join the array into a string separated by ', '
  const formattedString = wordsArray.join(', ');

  // Return a <p> element with the formatted string
  return formattedString;
};

const ProductDetailComponent = ({ product }) => {
  const { name, images, description, metadata } = product;
  const [index, setIndex] = useState(0);

  const listRef = useRef(null);
  const [listHeight, setListHeight] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  const handleImageError = (e) => {
    e.target.src = config.imageNotFound;
  };

  useEffect(() => {
    if (listRef.current) {
      setListHeight(listRef.current.clientHeight * 0.9);
    }
  }, []);

  return (
    <Container className='mt-2'>
      <Row>
        <Col md={7} lg={5}>
          {images && images.length > 0 ? (
            <>
              <Carousel
                activeIndex={index}
                onSelect={handleSelect}
                interval={null}
              >
                {images.map((image, id) => (
                  <Carousel.Item key={id}>
                    <img
                      className='d-block w-100'
                      src={image}
                      alt={`Slide ${id}`}
                      onError={handleImageError}
                    />
                  </Carousel.Item>
                ))}
              </Carousel>

              <Row className='mt-3'>
                <Col>
                  {images.map((image, idx) => (
                    <div
                      key={idx}
                      style={{ margin: '5px', display: 'inline-block' }}
                    >
                      <Image
                        src={image}
                        alt={`Preview ${idx}`}
                        rounded
                        onClick={() => handleSelect(idx)}
                        className='img-fluid'
                        style={{ cursor: 'pointer', maxHeight: '75px' }}
                        onError={handleImageError}
                      />
                    </div>
                  ))}
                </Col>
              </Row>
            </>
          ) : (
            <Carousel.Item>
              <img
                className='d-block w-100'
                src={config.placeholderImageUrl}
                alt='Placeholder'
              />
            </Carousel.Item>
          )}
        </Col>
        <Col md={5}>
          <Row className='pt-5 pt-lg-0'>
            <h2>{name}</h2>
            <Col className='pt-3' xs={12} md={8} lg={6}>
              <ProductActions product={product} />
            </Col>
          </Row>

          <Row className='align-items-center pt-5'>
            <Col>
              <h5>PRODUKTDETAILS</h5>
              <hr style={{ borderTop: '1px solid gray' }} />
              <Row className='d-flex align-items-center'>
                <Col xs='auto'>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      height: `${listHeight}px`
                    }}
                  >
                    <img
                      src={klappCardImg}
                      alt='Klappkarte'
                      style={{
                        height: '100%',
                        width: 'auto'
                      }}
                    />
                  </div>
                </Col>
                <Col>
                  <ul ref={listRef} className='product-details-list'>
                    <li>
                      <span>Kartentyp: Klappkarte, Faltkarte</span>
                    </li>
                    <li>
                      <span>Material: 320 g/m² CoffeeCup-Recyclingpapier</span>
                    </li>
                    <li>
                      <span>Format: DIN A 6</span>
                    </li>
                    <li>
                      <span>
                        Inkl. Passendem Umschlag aus hochwertigem Kraftpapier
                      </span>
                    </li>
                    <li>
                      <span>5er Set oder 10er Set</span>
                    </li>
                  </ul>
                </Col>
              </Row>

              <h5 className='mt-3'>BESCHREIBUNG</h5>
              <hr style={{ borderTop: '1px solid gray' }} />
              <p style={{ wordBreak: 'break-word' }}>{description}</p>
              <h5>ANLASS</h5>
              <hr style={{ borderTop: '1px solid gray' }} />
              <p>{formatCategoryString(metadata['category'])}</p>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default ProductDetailComponent;
