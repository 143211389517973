import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import allowCookies from '../utils/allowCookies';
import declineCookies from '../utils/declineCookies';
import Cookies from 'js-cookie';

const CookieConsentToggle = () => {
  // Initialize consent state based on the current cookie value
  const [consentGiven, setConsentGiven] = useState(
    Cookies.get('cookieConsent') === 'true'
  );

  useEffect(() => {
    // Update state if the cookie consent changes externally
    const checkConsent = Cookies.get('cookieConsent') === 'true';
    if (consentGiven !== checkConsent) {
      setConsentGiven(checkConsent);
    }
  }, [consentGiven]);

  const handleToggle = (e) => {
    const newConsentGiven = e.target.checked;
    setConsentGiven(newConsentGiven); // Update state to trigger re-render
    if (newConsentGiven) {
      allowCookies();
    } else {
      declineCookies();
    }
  };

  return (
    <Form>
      <Form.Check
        type='switch'
        id='cookie-consent-switch'
        label={consentGiven ? 'Cookies aktiviert' : 'Cookies deaktiviert'}
        checked={consentGiven}
        onChange={handleToggle}
        className='mt-3'
      />
    </Form>
  );
};

export default CookieConsentToggle;
