// cartSlice.js
import { createSlice } from '@reduxjs/toolkit';
import { logoutUser } from './userSlice'; // Import the logoutUser action

const MAX_QUANTITY = 200; // Maximum quantity allowed

const cartSlice = createSlice({
  name: 'cart',
  initialState: {
    items: [] // Array of { id, name, quantity, image, price }
  },
  reducers: {
    addItem: (state, action) => {
      const {
        id,
        name,
        quantity = 1,
        price_id,
        price,
        currency,
        imageUrl
      } = action.payload;
      const existingItem = state.items.find((item) => item.id === id);
      if (existingItem) {
        // Calculate new quantity and ensure it doesn't exceed maximum
        const newQuantity = Math.min(
          existingItem.quantity + quantity,
          MAX_QUANTITY
        );
        existingItem.quantity = newQuantity;
        // Optionally update price and imageUrl in case they change
        existingItem.price = price;
        existingItem.imageUrl = imageUrl;
      } else {
        // If item doesn't exist, add it with quantity capped at maximum
        state.items.push({
          id,
          name,
          quantity: Math.min(quantity, MAX_QUANTITY),
          price_id,
          price,
          currency,
          imageUrl // Ensure the item includes the price and imageUrl when added
        });
      }
    },
    changePrice: (state, action) => {
      const { id, price } = action.payload;
      const itemToUpdate = state.items.find((item) => item.id === id);
      if (itemToUpdate) {
        // Ensure new quantity does not exceed maximum
        //itemToUpdate.quantity = quantity;
        itemToUpdate.price = price;
      }
    },
    updateQuantity: (state, action) => {
      const { id, quantity } = action.payload;
      const itemToUpdate = state.items.find((item) => item.id === id);
      if (itemToUpdate) {
        // Ensure new quantity does not exceed maximum
        itemToUpdate.quantity = Math.min(quantity, MAX_QUANTITY);
      }
    },
    removeItem: (state, action) => {
      const index = state.items.findIndex(
        (item) => item.id === action.payload.id
      );
      if (index !== -1) {
        state.items.splice(index, 1);
      }
    },
    clearCart: (state) => {
      state.items = [];
    }
  },
  extraReducers: (builder) => {
    builder.addCase(logoutUser.fulfilled, (state, action) => {
      // Clear the cart when the user successfully logs out
      state.items = [];
    });
    // You can listen to more user-related actions if needed
  }
});

export const { addItem, removeItem, updateQuantity, clearCart, changePrice } =
  cartSlice.actions;
export default cartSlice.reducer;
