import React from 'react';
import Button from 'react-bootstrap/Button';
import axiosInstance from '../axiosConfig'; // Adjust the path as necessary
import { addMessage } from '../redux/messageSlice'; // Adjust path as necessary
import { useDispatch } from 'react-redux';

const SendConfirmationEmailButton = () => {
  const dispatch = useDispatch();
  const handleSendConfirmationEmail = async () => {
    try {
      await axiosInstance.post('users/api/send-confirmation-email/');

      dispatch(
        addMessage({
          text: 'Bestätigungs-Email erfolgreich gesendet.',
          messageType: 'success',
          dismissible: true
        })
      );
    } catch (error) {
      dispatch(
        addMessage({
          text: 'Fehler beim Senden der Bestätigungs-Email, bitte versuchen Sie es später erneut.',
          messageType: 'danger',
          dismissible: true
        })
      );
    }
  };

  return (
    <Button onClick={handleSendConfirmationEmail} variant='secondary'>
      Bestätigungs-Email erneut senden.
    </Button>
  );
};

export default SendConfirmationEmailButton;
