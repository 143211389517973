// InvoiceForm.js
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Form, Button, InputGroup } from 'react-bootstrap';
import DOMPurify from 'dompurify';
import axiosInstance from '../axiosConfig';
import { clearCart } from '../redux/cartSlice';

const InvoiceForm = ({ items, setErrorMessage, setSuccessMessage }) => {
  const [formData, setFormData] = useState({
    name: '',
    line1: '',
    line2: '',
    postalCode: '',
    city: '',
    country: 'DE',
    invoiceName: '',
    invoiceLine1: '',
    invoiceLine2: '',
    invoicePostalCode: '',
    invoiceCity: '',
    invoiceCountry: 'DE',
    equalsInvoiceAddress: true
  });

  const dispatch = useDispatch();

  useEffect(() => {
    // Fetch user information from endpoint
    axiosInstance
      .get('/api/get-customer-addresses/')
      .then((response) => {
        console.log(response.data);
        const { address, shipping, name } = response.data;
        // Initialize the form with fetched data
        setFormData({
          name: shipping.name || '',
          line1: shipping.address.line1 || '',
          line2: shipping.address.line2 || '',
          postalCode: shipping.address.postal_code || '',
          city: shipping.address.city || '',
          country: shipping.address.country || 'DE',
          invoiceName: name || '',
          invoiceLine1: address.line1 || '',
          invoiceLine2: address.line2 || '',
          invoicePostalCode: address.postal_code || '',
          invoiceCity: address.city || '',
          invoiceCountry: address.country || 'DE',
          equalsInvoiceAddress:
            address.line1 === shipping.address.line1 &&
            address.city === shipping.address.city &&
            address.postal_code === shipping.address.postal_code &&
            address.country === shipping.address.country
        });
      })
      .catch((error) => {
        console.log(error);
        // setErrorMessage(
        //   'Fehler beim Abrufen der Kundendaten. Bitte versuchen Sie es später erneut.'
        // );
      });
  }, [setErrorMessage]);

  const handleInvoiceCreation = (e) => {
    e.preventDefault(); // Prevent the default behavior of the click event

    const shipping_address = {
      city: formData.city || null,
      country: formData.country || null, // Adjust as necessary
      line1: formData.line1 || null,
      line2: formData.line2 || null,
      postal_code: formData.postalCode || null,
      state: null // Assuming the state is not included in the form, set it to null
    };

    const shipping_customer_name = formData.name || null;
    const invoice_customer_name = formData.invoiceName || null;

    const invoice_address = {
      city: formData.invoiceCity || null,
      country: formData.invoiceCountry || null, // Adjust as necessary
      line1: formData.invoiceLine1 || null,
      line2: formData.invoiceLine2 || null,
      postal_code: formData.invoicePostalCode || null,
      state: null // Assuming the state is not included in the form, set it to null
    };

    const equal_addresses = formData.equalsInvoiceAddress;

    axiosInstance
      .post('/api/create-invoice/', {
        line_items: items.map((item) => ({
          price: item.price.id,
          quantity: item.quantity
        })),
        shipping_customer_address: shipping_address,
        shipping_customer_name: shipping_customer_name,
        invoice_customer_address: invoice_address,
        invoice_customer_name: invoice_customer_name,
        equal_addresses: equal_addresses
      })
      .then((response) => {
        const sanitizedUrl = DOMPurify.sanitize(response.data.invoice_url);
        const sanitizedMessage = `Vielen Dank für deine Bestellung. In Kürze erhältst Du die Rechnung per Mail. 
                      <a class='alert-link'  href="${sanitizedUrl}" target="_blank" rel="noopener noreferrer">Hier kannst du die Rechnung ansehen.</a>`;
        setSuccessMessage(sanitizedMessage);
        dispatch(clearCart());
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          setErrorMessage(
            'Rechnung kann nur von registrierten Benutzern erstellt werden. Bitte loggen Sie sich ein oder registrieren Sie sich.'
          );
        } else {
          setErrorMessage(
            'Rechnung konnte nicht erstellt werden. Bitte probieren Sie es später nocheinmal oder kontaktieren unseren Support.'
          );
        }
      });
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  return (
    <Form onSubmit={handleInvoiceCreation}>
      <Form.Group className='mb-3'>
        <Form.Label>Versandadresse</Form.Label>
        <Form.Control
          required
          type='text'
          name='name'
          value={formData.name}
          onChange={handleChange}
          placeholder='Vollständiger Name'
        />
        <Form.Control
          required
          type='text'
          name='line1'
          value={formData.line1}
          onChange={handleChange}
          placeholder='Adresszeile 1 (Straße, Hausnummer)'
        />
        <Form.Control
          type='text'
          name='line2'
          value={formData.line2}
          onChange={handleChange}
          placeholder='Adresszeile 2 (optional)'
        />
        <InputGroup className='mb-3'>
          <Form.Control
            required
            type='text'
            name='postalCode'
            value={formData.postalCode}
            onChange={handleChange}
            placeholder='Postleitzahl'
          />
          <Form.Control
            required
            type='text'
            name='city'
            value={formData.city}
            onChange={handleChange}
            placeholder='Ort'
          />
        </InputGroup>

        <Form.Group controlId='formCountry' className='mb-3'>
          <Form.Control
            as='select'
            name='country'
            value={formData.country}
            onChange={handleChange}
            required
          >
            <option value='DE'>Deutschland</option>
            <option value='AT'>Österreich</option>
            <option value='CH'>Schweiz</option>
          </Form.Control>
        </Form.Group>

        <Form.Check
          type='checkbox'
          name='equalsInvoiceAddress'
          checked={formData.equalsInvoiceAddress}
          onChange={handleChange}
          label='Rechnungsadresse identisch mit Versandadresse'
        />

        {!formData.equalsInvoiceAddress && (
          <>
            <Form.Label>Rechnungsadresse</Form.Label>
            <Form.Control
              required
              type='text'
              name='invoiceName'
              value={formData.invoiceName}
              onChange={handleChange}
              placeholder='Vollständiger Name'
            />
            <Form.Control
              required
              type='text'
              name='invoiceLine1'
              value={formData.invoiceLine1}
              onChange={handleChange}
              placeholder='Adresszeile 1 (Straße, Hausnummer)'
            />
            <Form.Control
              type='text'
              name='invoiceLine2'
              value={formData.invoiceLine2}
              onChange={handleChange}
              placeholder='Adresszeile 2 (optional)'
            />
            <InputGroup className='mb-3'>
              <Form.Control
                required
                type='text'
                name='invoicePostalCode'
                value={formData.invoicePostalCode}
                onChange={handleChange}
                placeholder='Postleitzahl'
              />
              <Form.Control
                required
                type='text'
                name='invoiceCity'
                value={formData.invoiceCity}
                onChange={handleChange}
                placeholder='Ort'
              />
            </InputGroup>

            <Form.Group controlId='formInvoiceCountry' className='mb-3'>
              <Form.Control
                as='select'
                name='invoiceCountry'
                value={formData.invoiceCountry}
                onChange={handleChange}
                required
              >
                <option value='DE'>Deutschland</option>
                <option value='AT'>Österreich</option>
                <option value='CH'>Schweiz</option>
              </Form.Control>
            </Form.Group>
          </>
        )}
        <Button variant='primary' type='submit'>
          Auf Rechnung bestellen
        </Button>
      </Form.Group>
    </Form>
  );
};

export default InvoiceForm;
