import { clearMessages } from '../redux/messageSlice';
import { logoutUser } from '../redux/userSlice';

export const logout = async (dispatch, navigate) => {
  // Dispatch clearMessages action to clear any messages
  dispatch(clearMessages());

  // Dispatch logoutUser action to logout the user
  await dispatch(logoutUser()).unwrap();

  // Navigate to the specified location (e.g., home page)
  navigate('/');
};
