import React, { useEffect, useState } from 'react';
import axiosInstance from '../axiosConfig';
import OrderCardComponent from '../components/Order';
import { useSelector } from 'react-redux';
import ProfilePageLayout from '../layouts/ProfilePageLayout';

function OrdersPage() {
  const loggedIn = useSelector((state) => state.user.loggedIn);
  const [invoices, setInvoices] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (loggedIn) {
      setLoading(true);
      const cacheBuster = new Date().getTime(); // Current timestamp - so request is unique and caching is avoided
      axiosInstance
        .get(`/api/invoice/me?_=${cacheBuster}`)
        .then(async (response) => {
          const invoices = response.data;

          // Use Promise.all to wait for all invoice details to be fetched
          const invoiceWithDetailsPromises = invoices.map(async (invoice) => {
            // Await the axios call to get invoice items
            const response = await axiosInstance.get(
              `/api/invoice-item/me/${invoice.id}`
            );
            const invoiceItems = response.data;

            const invoiceItemsWithDetailsPromises = invoiceItems.map(
              async (invoiceItem) => {
                const response = await axiosInstance.get(
                  `/api/line-item/me/${invoiceItem.id}`
                );
                const lineItem = response.data;

                const product_response = await axiosInstance.get(
                  `/api/product/${lineItem.price.product}`
                );
                const product = product_response.data;
                // Return the invoice item with its line items appended
                return {
                  ...invoiceItem,
                  line_item: lineItem,
                  product: product
                };
              }
            );

            // Wait for all line items to be fetched for each invoice item
            const invoiceItemsWithDetails = await Promise.all(
              invoiceItemsWithDetailsPromises
            );

            // Append the fetched invoice items to the invoice object
            return { ...invoice, invoice_items: invoiceItemsWithDetails };
          });

          // Wait for all the invoice details promises to resolve
          return Promise.all(invoiceWithDetailsPromises);
        })
        .then((invoicesWithLineItemDetails) => {
          setInvoices(invoicesWithLineItemDetails);
          setLoading(false);
        })
        .catch((error) => {
          setError(error);
          console.error('Failed to fetch invoices with details:', error);
        });
    }
  }, [loggedIn]);

  // Helper function to sort invoices by date
  const sortByDate = (invoices) => {
    return invoices.sort((a, b) => new Date(b.date) - new Date(a.date));
  };

  // Separate invoices into open and paid
  const openInvoices = invoices.filter((invoice) => !invoice.paid);
  const paidInvoices = invoices.filter((invoice) => invoice.paid);

  // Sort each category by date
  const sortedOpenInvoices = sortByDate(openInvoices);
  const sortedPaidInvoices = sortByDate(paidInvoices);

  // Prepare content based on loading, error, and orders status
  let content;
  if (!loggedIn) {
    content = (
      <div>Bitte loggen Sie sich ein, um ihre Bestellungen zu sehen.</div>
    );
  } else if (loading) {
    content = <div>Lade Bestellungen...</div>;
  } else if (error) {
    content = <div>Fehler: {error}</div>;
  } else {
    content = (
      <>
        <section>
          <h2>Offene Rechnungen</h2>
          {sortedOpenInvoices.length === 0 ? (
            <div>Keine offenen Rechnungen.</div>
          ) : (
            sortedOpenInvoices.map((order, index) => (
              <OrderCardComponent key={index} order={order} />
            ))
          )}
        </section>
        <section>
          <h2>Bezahlte Rechnungen</h2>
          {sortedPaidInvoices.length === 0 ? (
            <div>Keine bezahlten Rechnungen.</div>
          ) : (
            sortedPaidInvoices.map((order, index) => (
              <OrderCardComponent key={index} order={order} />
            ))
          )}
        </section>
      </>
    );
  }

  return <ProfilePageLayout>{content}</ProfilePageLayout>;
}

export default OrdersPage;
