import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Button, Alert, InputGroup, Col, Row } from 'react-bootstrap';
import { fetchUser, updateUser } from '../redux/userSlice'; // Adjust the import path as necessary

function UserUpdateForm() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);
  const [isEditable, setIsEditable] = useState(false);
  const [submitStatus, setSubmitStatus] = useState({ status: '', message: '' });

  const [formData, setFormData] = useState({
    anrede: '',
    first_name: '',
    last_name: '',
    birthday: '',
    is_email_confirmed: false
  });

  // Fetch user data only when shouldFetchUser is true
  useEffect(() => {
    dispatch(fetchUser());
  }, [dispatch]);

  useEffect(() => {
    if (user) {
      setFormData(user);
    }
  }, [user]);

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleAlertDismiss = () => {
    setSubmitStatus({
      status: null,
      message: ''
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await dispatch(updateUser(formData)).unwrap();
      setSubmitStatus({
        status: 'success',
        message: 'Persönliche Informationen erfolgreich aktualisiert.'
      });
      setIsEditable(false); // Optionally disable editing after successful submission
    } catch (error) {
      setSubmitStatus({
        status: 'error',
        message: 'Fehler beim Aktualisieren der Nutzerdaten.'
      });
    }
  };
  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col>
            <InputGroup>
              <InputGroup.Text style={{ minWidth: '150px' }}>
                Anrede
              </InputGroup.Text>
              <Form.Control
                as='select'
                name='anrede'
                value={formData.anrede}
                onChange={handleChange}
                disabled={!isEditable}
              >
                <option key='blankChoice' hidden value>
                  --Wähle Anrede--
                </option>
                <option value='Herr'>Herr</option>
                <option value='Frau'>Frau</option>
              </Form.Control>
            </InputGroup>
          </Col>
        </Row>

        <Row>
          <Col>
            <InputGroup>
              <InputGroup.Text style={{ minWidth: '150px' }}>
                Vorname
              </InputGroup.Text>
              <Form.Control
                type='text'
                name='first_name'
                value={formData.first_name}
                onChange={handleChange}
                placeholder='Vorname'
                readOnly={!isEditable}
              />
            </InputGroup>
          </Col>
        </Row>

        <Row>
          <Col>
            <InputGroup>
              <InputGroup.Text style={{ minWidth: '150px' }}>
                Nachname
              </InputGroup.Text>
              <Form.Control
                type='text'
                name='last_name'
                value={formData.last_name}
                onChange={handleChange}
                placeholder='Nachname'
                readOnly={!isEditable}
              />
            </InputGroup>
          </Col>
        </Row>

        <Row>
          <Col>
            <InputGroup>
              <InputGroup.Text style={{ minWidth: '150px' }}>
                Geburtstag
              </InputGroup.Text>
              <Form.Control
                type='date'
                name='birthday'
                value={formData.birthday}
                onChange={handleChange}
                readOnly={!isEditable}
              />
            </InputGroup>
          </Col>
        </Row>
        <Button
          className='mt-3'
          variant='secondary'
          onClick={() => setIsEditable(!isEditable)}
        >
          {isEditable ? 'Abbrechen' : 'Bearbeiten'}
        </Button>
        {isEditable && (
          <Button className='mt-3 ms-3' variant='primary' type='submit'>
            Aktualisieren
          </Button>
        )}
      </Form>

      {submitStatus.status && (
        <Alert
          variant={submitStatus.status === 'success' ? 'success' : 'danger'}
          className='mt-3'
          dismissible
          onClose={handleAlertDismiss}
        >
          {submitStatus.message}
        </Alert>
      )}
    </>
  );
}

export default UserUpdateForm;
