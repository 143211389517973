import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import cartReducer from './cartSlice';
import userReducer from './userSlice';
import messageReducer from './messageSlice';

import { logoutMiddleware } from './logoutMiddleware.js';

// Configuration for redux-persist
const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['cart', 'user'] // Add slice reducers you want to persist here
};

// Combine reducers
const rootReducer = combineReducers({
  cart: cartReducer,
  user: userReducer,
  message: messageReducer
});

// Apply redux-persist to the rootReducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [
          'persist/PERSIST',
          'persist/REHYDRATE',
          'persist/PAUSE',
          'persist/PERSIST',
          'persist/PURGE',
          'persist/REGISTER'
        ]
      }
    }).concat(logoutMiddleware) // Adding logoutMiddleware here
});

export const persistor = persistStore(store);
