import { useEffect, useRef, useCallback } from 'react';
import { useQuery } from '../hooks/useQuery'; // Adjust path as necessary
import { useDispatch, useSelector } from 'react-redux';
import { addMessage } from '../redux/messageSlice'; // Adjust path as necessary
import { clearCart } from '../redux/cartSlice'; // Adjust path as necessary

const GlobalParseQuery = () => {
  const query = useQuery();
  const dispatch = useDispatch();
  const messages = useSelector((state) => state.messages); // Assuming messages are stored in Redux state
  const messagesBeingAdded = useRef(new Set());

  const addMessageIfNotExist = useCallback(
    (text, messageType, messages) => {
      if (text && messageType) {
        const existingMessages = Array.isArray(messages) ? messages : [];
        const messageIdentifier = `${text}-${messageType}`;

        if (
          !existingMessages.find(
            (msg) => msg.text === text && msg.messageType === messageType
          ) &&
          !messagesBeingAdded.current.has(messageIdentifier)
        ) {
          messagesBeingAdded.current.add(messageIdentifier);
          dispatch(
            addMessage({
              text: text,
              messageType: messageType,
              dismissible: true
            })
          );
        }
      }
    },
    [dispatch]
  );

  useEffect(() => {
    const text = query.get('text');
    const messageType = query.get('messageType');

    addMessageIfNotExist(text, messageType, messages);

    const clearCartParam = query.get('clearCart');
    const clearCartFlag = ['true', '1', 't', 'y', 'yes'].includes(
      clearCartParam?.toLowerCase() ?? ''
    );

    if (clearCartFlag) {
      dispatch(clearCart());
    }

    // Clean up the messagesBeingAdded set to prevent memory leaks
    return () => {
      // Note: Do not clear the ref here, as it should persist across renders
    };
  }, [dispatch, query, messages, addMessageIfNotExist]);
};

export default GlobalParseQuery;
