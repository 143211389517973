import RequestPasswordResetForm from '../components/RequestPasswordResetForm';
import BaseLayout from '../layouts/BaseLayout';

function ResetPasswordPage() {
  //TODO: only display if not logged in

  const content = <RequestPasswordResetForm></RequestPasswordResetForm>;

  return <BaseLayout children={content}></BaseLayout>;
}

export default ResetPasswordPage;
