//MAIN CONFIG FILE FOR FlexStores

// Specify API URL of Backend. Note: Due to || this is not included in config object.
export const API_URL = process.env.REACT_APP_API_URL || 'http://127.0.0.1:8000';

const config = {
  storeName: 'miapapeteria',
  logoFilename: 'logo512.png', // Relative path to the logo in the public folder
  favicon: '/favicon.ico', // this setting has no effect, its just a reminder to replace favicon!
  imageNotFound: require('../assets/images/imageNotFound.png'),
  somethingWentWrong: require('../assets/images/somethingWentWrong.png'),
  underConstruction: require('../assets/images/underConstruction.png')
  //   colorScheme: {
  //     primaryColor: '#336699',
  //     secondaryColor: '#FF9900'
  //   },
};

export default config;
