import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Alert } from 'react-bootstrap';
import axiosInstance from '../axiosConfig';

import { updateSubscriptionStatus } from '../redux/userSlice'; // Adjust the import path as needed

function ToggleNewsletter() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);

  const toggleSubscription = () => {
    const newSubscriptionStatus = !user.is_subscribed;
    axiosInstance
      .post('/users/api/subscribe/', { is_subscribed: newSubscriptionStatus })
      .then((response) => {
        const subscriptionStatus = response.data.is_subscribed;
        dispatch(updateSubscriptionStatus(subscriptionStatus)); // Update based on the actual response
      })
      .catch((error) =>
        console.error('Error updating subscription status:', error)
      );
  };

  // // Fetch user data only when shouldFetchUser is true
  // useEffect(() => {
  //   dispatch(fetchUser());
  // }, [dispatch]);

  return (
    <>
      {user.is_subscribed ? (
        <Alert variant='success' className='mt-2'>
          Sie haben den Newsletter derzeit abonniert und werden damit über
          Neuigkeiten und Rabatte informiert.
        </Alert>
      ) : (
        <Alert variant='danger' className='mt-2'>
          Sie haben den Newsletter aktuell <strong> nicht </strong>abonniert und
          erhalten daher <strong>keine</strong> Informationen über Neuigkeiten
          und Rabatte.
        </Alert>
      )}
      <Button
        onClick={toggleSubscription}
        variant={user.is_subscribed ? 'secondary' : 'primary'}
      >
        {user.is_subscribed ? 'Deabonniere Newsletter' : 'Abonniere Newsletter'}
      </Button>
    </>
  );
}

export default ToggleNewsletter;
