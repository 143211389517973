import axiosInstance from '../axiosConfig';
import { Button, Form, Alert, Spinner } from 'react-bootstrap';
import React, { useState } from 'react';
import { handleErrorResponse } from '../utils/errors'; // Import from the correct path

const ResetPasswordForm = () => {
  const [email, setEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const handleEmailChange = (e) => setEmail(e.target.value);

  const handleSubmit = async () => {
    setLoading(true);
    axiosInstance
      .post('users/api/password_reset/', { email: email })
      .then(() => {
        setSuccessMessage(
          `E-Mail zum zurücksetzen des Passwortes gesendet an: ${email}`
        );
        setErrorMessage('');
        setLoading(false);
      })
      .catch(function (error) {
        setLoading(false);
        setSuccessMessage('');
        handleErrorResponse(error, setErrorMessage);
      });
  };

  return (
    <>
      <h1>Setze dein Passwort zurück</h1>
      <p>
        Gib deine E-Mail-Adresse ein und wir schicken dir einen Link zum
        Zurücksetzen deines Passworts.
      </p>
      <form onSubmit={handleSubmit}>
        <Form.Group className='mb-3' controlId='formBasicEmail'>
          <Form.Label>E-Mail-Adresse</Form.Label>
          <Form.Control
            type='email'
            value={email}
            onChange={handleEmailChange}
            selectionColor='red'
          />
        </Form.Group>

        {/* Note: set button to deactiveated when loading */}
        <Button
          variant='primary'
          type='submit'
          onClick={handleSubmit}
          disabled={loading}
        >
          {loading ? (
            <>
              <Spinner
                as='span'
                animation='border'
                size='sm'
                role='status'
                aria-hidden='true'
                className='me-2'
              />
              Laden...
            </>
          ) : (
            'Login-Link per Email zusenden'
          )}
        </Button>
      </form>
      {successMessage && (
        <Alert className='mt-2' variant='success'>
          {successMessage}
        </Alert>
      )}
      {errorMessage && (
        <Alert className='mt-2' variant='danger'>
          {errorMessage}
        </Alert>
      )}
    </>
  );
};

export default ResetPasswordForm;
