import CookieConsentToggle from '../components/CookieConsentToggle';
import ReloadButton from '../components/ReloadPageButton';
import BaseLayout from '../layouts/BaseLayout';

function CookieSettingsPage() {
  const content = (
    <>
      <h1>Cookie Einstellungen</h1>
      <p>Cookies helfen uns Ihnen eine bessere Nutzererfahrung zu bieten.</p>
      <p>Hier können Sie Cookies aktivieren bzw. deaktivieren.</p>{' '}
      <CookieConsentToggle></CookieConsentToggle> <ReloadButton />
    </>
  );
  return <BaseLayout children={content}></BaseLayout>;
}

export default CookieSettingsPage;
