import axiosInstance from '../axiosConfig';

export const withRetryOnUnauthorized = (apiCall) => {
  // Use store.dispatch to dispatch any Redux action
  const attemptApiCall = async (args, attempt = 1) => {
    try {
      return await apiCall(args);
    } catch (error) {
      if (error.response && error.response.status === 401 && attempt <= 2) {
        //remove invalid token
        localStorage.removeItem('authToken');
        // Remove the Authorization header from future Axios requests
        delete axiosInstance.defaults.headers.common['Authorization'];

        return attemptApiCall(args, attempt + 1);
      } else {
        throw error;
      }
    }
  };

  return attemptApiCall;
};
