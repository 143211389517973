// utils/declineCookies.js
import Cookies from 'js-cookie';

const declineCookies = () => {
  Cookies.set('cookieConsent', 'false', { expires: 365 });
  // Function to delete all cookies except 'cookieConsent'
  const deleteAllCookiesExceptConsent = () => {
    const allCookies = Cookies.get(); // Retrieves an object with all cookies
    Object.keys(allCookies).forEach((cookieName) => {
      if (cookieName !== 'cookieConsent') {
        Cookies.remove(cookieName); // Deletes each cookie except for 'cookieConsent'
      }
    });
  };

  // Call the function to remove all cookies except the consent cookie
  deleteAllCookiesExceptConsent();
  console.log(
    "Cookies have been declined and removed, except for 'cookieConsent'."
  );
};

export default declineCookies;
