import React, { useState, useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';

import HomePage from './pages/HomePage';
import Header from './components/Header';
import config from './config';
import './App.scss'; // Import the SCSS file
import ResetPasswordPage from './pages/ResetPasswordPage';
import SetNewPasswordPage from './pages/SetNewPasswordPage';
import ProfilePage from './pages/ProfilePage';
//import { handleErrorResponse } from './utils/errors';
import OrdersPage from './pages/OrdersPage';
import ProductDetailPage from './pages/ProductDetailPage';
import CartPage from './pages/CartPage';
import { useSelector, useDispatch } from 'react-redux';
import { persistor } from './redux/store'; // Ensure you import your persistor

import { reinitializeAuth } from './redux/userSlice';
import Footer from './components/Footer';
import ShopPage from './pages/ShopPage';
import ImpressumPage from './pages/ImpressumPage';
import CookieConsentBanner from './components/CookieConsentBanner';
import CookieSettingsPage from './pages/CookieSettingsPage';
import ErrorPage from './pages/ErrorPage';
import AGBPage from './pages/AGBPage';
import DataPrivacyPage from './pages/DataPrivacyPage';

import GlobalParseQuery from './components/GlobalParseQuery.js';
import MessageComponent from './components/MessageComponent.js';

import { addMessage, removeMessage } from './redux/messageSlice';

import useLogout from './hooks/useLogout'; // Adjust the path as necessary
import { Container, Row, Col } from 'react-bootstrap';

const Logout = ({ navigate }) => {
  const handleLogout = useLogout(navigate);
  handleLogout();

  return null;
};

const App = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const loggedIn = useSelector((state) => state.user.loggedIn);
  const messages = useSelector((state) => state.messages); // Assuming messages are stored in Redux state

  const isEmailConfirmed = useSelector(
    (state) => state.user.user?.is_email_confirmed ?? true
  );

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const initialize = async () => {
      await dispatch(reinitializeAuth()).unwrap();
      setIsLoading(false);
    };

    initialize();
  }, [dispatch]);

  useEffect(() => {
    if (!isLoading) {
      if (!loggedIn) {
        persistor.purge(['user']); // Specify the key of the user slice if needed
      }

      document.title = config.storeName;

      if (loggedIn) {
        const confirmationMessage = 'Bitte bestätigen Sie Ihre E-Mail-Adresse.';
        if (!isEmailConfirmed) {
          if (
            !messages ||
            !messages.some((msg) => msg.text === confirmationMessage)
          ) {
            dispatch(
              addMessage({
                text: confirmationMessage,
                messageType: 'info',
                dismissible: false
              })
            );
          }
        } else if (messages && messages.length > 0) {
          const messagesToRemove = messages.filter(
            (msg) => msg.text === confirmationMessage
          );
          messagesToRemove.forEach((message) => {
            dispatch(removeMessage(message.id));
          });
        }
      }
    }
  }, [isEmailConfirmed, dispatch, isLoading, loggedIn, messages]);

  return (
    <div className='d-flex flex-column min-vh-100'>
      <CookieConsentBanner />

      <Header />

      <GlobalParseQuery />
      <Container fluid className='flex-grow-1 d-flex p-0'>
        <Row className='m-0 flex-grow-1'>
          <Col className='p-0'>
            <MessageComponent />

            <Routes>
              <Route path='/' element={<HomePage />} />
              <Route path='/shop' element={<ShopPage />} />
              <Route
                path='/product/:productId'
                element={<ProductDetailPage />}
              />
              {/* <Route path='/dashboard' element={<DashboardPage />} /> */}
              <Route path='/profile' element={<ProfilePage />} />
              <Route path='/orders' element={<OrdersPage />} />
              <Route path='/cart' element={<CartPage />} />
              <Route
                path='/logout'
                element={<Logout navigate={navigate}></Logout>}
              />
              <Route path='/reset_password' element={<ResetPasswordPage />} />
              <Route path='/impressum' element={<ImpressumPage />} />
              <Route path='/agb' element={<AGBPage />} />
              <Route path='/data-privacy' element={<DataPrivacyPage />} />
              <Route path='/cookie-settings' element={<CookieSettingsPage />} />
              <Route
                path='/reset_password/confirm'
                element={<SetNewPasswordPage />}
              />
              <Route path='*' element={<ErrorPage />} />
            </Routes>
          </Col>
        </Row>
      </Container>

      <Footer />
    </div>
  );
};

export default App;
