import BaseLayout from '../layouts/BaseLayout';
import { Container, Row, Col } from 'react-bootstrap';

function DataPrivacyPage() {
  const content = (
    <Container>
      <Row>
        <Col>
          <h3 className='h3'>Datenschutzerklärung</h3>
          <p>
            <strong>Inhaltverzeichnis</strong>
          </p>
          <p>
            <a href='#responsible'>
              I. Name und Anschrift des Verantwortlichen
            </a>
            <br />
            <a href='#general'>II. Allgemeines zur Datenverarbeitung</a>
            <br />
            <a href='#orders'>III. Bestellungen</a>
            <br />
            <a href='#payment'>IV. Bezahlmöglichkeiten</a>
            <br />
            <a href='#log-files'>
              V. Bereitstellung der Website und Erstellung von Logfiles
            </a>
            <br />
            <a href='#cookies'>VI. Verwendung von Cookies</a>
            <br />
            <a href='#newsletter'>VII. Newsletter</a>
            <br />
            <a href='#registration'>VIII. Registrierung</a>
            <br />
            <a href='#matomo'>IX. Webanalyse durch Matomo</a>
            <br />
            <a href='#user-rights'>X. Rechte der betroffenen Person</a>
            <br />
          </p>

          <div id='responsible'>
            <h4>I. Name und Anschrift des Verantwortlichen</h4>
            <p>
              Der Verantwortliche im Sinne der Datenschutz-Grundverordnung und
              anderer nationaler Datenschutzgesetze der Mitgliedsstaaten sowie
              sonstiger datenschutzrechtlicher Bestimmungen ist die:
            </p>
            <p>
              miapapeteria <br></br>Heuwaagstraße 7<br></br>91054 Erlangen
              <br></br>Deutschland
            </p>
            <p>
              <strong>Telefon:</strong> +49 1573 7904180<br></br>
              <strong>Email:</strong> info@miapapeteria.de<br></br>
              <strong>Website:</strong> miapapeteria.de
            </p>
          </div>
          <div id='general'>
            <h4>II. Allgemeines zur Datenverarbeitung</h4>
            <h5>1. Umfang der Verarbeitung personenbezogener Daten</h5>{' '}
            <p>
              Wir verarbeiten personenbezogene Daten unserer Nutzer
              grundsätzlich nur, soweit dies zur Bereitstellung einer
              funktionsfähigen Website sowie unserer Inhalte und Leistungen
              erforderlich ist. Die Verarbeitung personenbezogener Daten unserer
              Nutzer erfolgt regelmäßig nur nach Einwilligung des Nutzers. Eine
              Ausnahme gilt in solchen Fällen, in denen eine vorherige Einholung
              einer Einwilligung aus tatsächlichen Gründen nicht möglich ist und
              die Verarbeitung der Daten durch gesetzliche Vorschriften
              gestattet ist.{' '}
            </p>
            <h5>
              2. Rechtsgrundlage für die Verarbeitung personenbezogener Daten
            </h5>
            <p>
              Soweit wir für Verarbeitungsvorgänge personenbezogener Daten eine
              Einwilligung der betroffenen Person einholen, dient Art. 6 Abs. 1
              lit. a EU-Datenschutzgrundverordnung (DSGVO) als Rechtsgrundlage.
              <br></br>
              Bei der Verarbeitung von personenbezogenen Daten, die zur
              Erfüllung eines Vertrages, dessen Vertragspartei die betroffene
              Person ist, erforderlich ist, dient Art. 6 Abs. 1 lit. b DSGVO als
              Rechtsgrundlage. Dies gilt auch für Verarbeitungsvorgänge, die zur
              Durchführung vorvertraglicher Maßnahmen erforderlich sind.
              <br></br> Soweit eine Verarbeitung personenbezogener Daten zur
              Erfüllung einer rechtlichen Verpflichtung erforderlich ist, der
              unser Unternehmen unterliegt, dient Art. 6 Abs. 1 lit. c DSGVO als
              Rechtsgrundlage.<br></br>
              Für den Fall, dass lebenswichtige Interessen der betroffenen
              Person oder einer anderen natürlichen Person eine Verarbeitung
              personenbezogener Daten erforderlich machen, dient Art. 6 Abs. 1
              lit. d DSGVO als Rechtsgrundlage.<br></br> Ist die Verarbeitung
              zur Wahrung eines berechtigten Interesses unseres Unternehmens
              oder eines Dritten erforderlich und überwiegen die Interessen,
              Grundrechte und Grundfreiheiten des Betroffenen das erstgenannte
              Interesse nicht, so dient Art. 6 Abs. 1 lit. f DSGVO als
              Rechtsgrundlage für die Verarbeitung.
            </p>
            <h5>3. Datenlöschung und Speicherdauer</h5>
            <p>
              Die personenbezogenen Daten der betroffenen Person werden gelöscht
              oder gesperrt, sobald der Zweck der Speicherung entfällt. Eine
              Speicherung kann darüber hinaus erfolgen, wenn dies durch den
              europäischen oder nationalen Gesetzgeber in unionsrechtlichen
              Verordnungen, Gesetzen oder sonstigen Vorschriften, denen der
              Verantwortliche unterliegt, vorgesehen wurde. Eine Sperrung oder
              Löschung der Daten erfolgt auch dann, wenn eine durch die
              genannten Normen vorgeschriebene Speicherfrist abläuft, es sei
              denn, dass eine Erforderlichkeit zur weiteren Speicherung der
              Daten für einen Vertragsabschluss oder eine Vertragserfüllung
              besteht.
            </p>
          </div>
          <div id='orders'>
            <h4>III. Bestellungen</h4>
            <p>
              Auf unserer Internetseite bieten wir Ihnen die Möglichkeit, unter
              Angabe personenbezogener Daten Waren zu bestellen. Im Rahmen des
              Bestellprozesses werden dabei personenbezogene Daten in eine
              Eingabemaske eingegeben und an uns übermittelt und gespeichert.
            </p>
            <ul>
              <li>Vorname, Nachmame</li>
              <li>Rechnungs- und Lieferanschrift(en)</li>
              <li>E-Mail Adresse</li>
              <li>Rechnungs- und Bezahldaten</li>
              <li>ggf. Telefonnummer für Rückfragen</li>
            </ul>
            <p>
              Rechtsgrundlage hierfür ist Artikel 6 Absatz 1 Buchstabe b) DSGVO,
              d.h. Sie stellen und die Daten auf Grundlage des
              Vertragsverhältnisses zwischen Ihnen und uns zur Verfügung. Zur
              Verarbeitung Ihrer E-Mailadresse sind wir zudem aufgrund
              gesetzlicher Vorgaben, eine elektronische Bestellbestätigung
              versenden zu müssen, verpflichtet (Artikel 6 Absatz 1 Buchstabe c)
              DSGVO). Soweit wir Ihre Kontaktdaten nicht für werbliche Zwecke
              nutzen (siehe unten 3.3), speichern wir die für die
              Vertragsabwicklung erhobenen Daten bis zum Ablauf der gesetzlichen
              Fristen bzw. möglicher vertraglicher Gewährleistungs- und
              Garantierechte. Nach Ablauf dieser Frist bewahren wir die nach
              Handels- und Steuerrecht erforderlichen Informationen des
              Vertragsverhältnisses für die gesetzlich bestimmten Zeiträume auf.
              Für diesen Zeitraum (regelmäßig zehn Jahre ab Vertragsschluss)
              werden die Daten allein für den Fall einer Überprüfung durch die
              Finanzverwaltung erneut verarbeitet.
            </p>
            <p>
              Zur Abwicklung des Kaufvertrages sind des Weiteren folgende
              Datenverarbeitungen erforderlich:
            </p>
            <p>
              Für den Bezahlvorgang, geben wir die erforderlichen Bezahldaten an
              einen von uns beauftragten Bezahldienstleister weiter, siehe
              Abschnitt "Bezahlmöglichkeiten".
            </p>
            <p>
              Des Weiteren geben wir die nötigen Angaben Ihrer Bestellung zum
              Zwecke der Abwicklung des Kaufvertrages an ein von uns
              beauftragtes Logistikunternehmen weiter. Um sicherzustellen, dass
              die Warenzustellung Ihren Wünschen entsprechend erfolgt,
              übermitteln wir Ihre E-Mailadresse und ggf. Ihre Telefonnummer an
              das von uns beauftragte Logistikunternehmen. Das
              Logistikunternehmen kann im Vorfeld der Zustellung mit Ihnen
              Kontakt aufnehmen, um Einzelheiten der Zustellung mit Ihnen
              abzustimmen. Die Daten werden allein zu diesem Zweck übermittelt
              und nach erfolgter Zustellung wieder gelöscht. Darüber hinaus
              gewährleisten wir, mit externen Anbietern die erforderlichen
              Auftragsverarbeitungsverträge abgeschlossen zu haben.
            </p>
          </div>
          <div id='payment'>
            <h4>IV. Bezahlmöglichkeiten</h4>
            <h5>Stripe</h5>
            <p>
              Für die Abwicklung von Zahlungen auf unserem Webshop nutzen wir
              den Dienstleister Stripe, Stripe Payments Europe, Limited (SPEL),
              1 Grand Canal Street, Lower Grand Canal Dock, Dublin, D02 H210,
              Irland. Dies entspricht unserem berechtigten Interesse, eine
              effiziente und sichere Zahlungsmethode anzubieten (Art. 6 Abs. 1
              lit. f DSGVO). In dem Zusammenhang geben wir folgende Daten an
              Stripe weiter, soweit es für die Vertragserfüllung erforderlich
              ist (Art. 6 Abs. 1 lit b. DSGVO). Um Ihre Bestellung abzuschließen
              und eine sichere Zahlung zu gewährleisten, werden Sie während des
              Checkout-Prozesses auf eine von Stripe gehostete Zahlungsseite
              weitergeleitet. Auf dieser Seite geben Sie Ihre
              Zahlungsinformationen direkt in die von Stripe bereitgestellte
              Schnittstelle ein.
            </p>
            <p>
              <ul>
                <li>Name des Karteninhabers</li>
                <li>E-Mail-Adresse</li>
                <li>Kundennummer</li>
                <li>Bestellnummer</li>
                <li>Bankverbindung</li>
                <li>Kreditkartendaten</li>
                <li>Gültigkeitsdauer der Kreditkarte</li>
                <li>Prüfnummer der Kreditkarte (CVC)</li>
                <li>Datum und Uhrzeit der Transaktion</li>
                <li>Transaktionssumme</li>
                <li>Lieferadresse</li>
              </ul>
            </p>
            <p>
              Datenübertragung an Stripe: Stripe erhebt und verarbeitet
              Informationen über Ihre Zahlungsmethode (z.B. Kreditkarten- oder
              Bankkontonummer, Paypal), Ihren Namen, Ihre Rechnungsadresse und
              gegebenenfalls weitere für die Transaktion notwendige
              Informationen. Diese Daten sind notwendig, um die
              Zahlungstransaktion auszuführen und gegen Betrug zu schützen. Die
              Verarbeitung Ihrer Zahlungsdaten durch Stripe erfolgt in
              Übereinstimmung mit deren Datenschutzrichtlinien, die Sie unter{' '}
              <a href='https://stripe.com/de/privacy'>
                Stripe Datenschutzrichtlinie
              </a>{' '}
              einsehen können.
            </p>
            <p>
              Wenn Sie PayPal als Zahlungsmethode wählen, werden Ihre für den
              Bezahlvorgang erforderlichen Daten zunächst über den
              Zahlungsdienstleister Stripe automatisch an PayPal übermittelt.
            </p>
            <p>
              Sicherheit: Stripe ist ein führender Anbieter von
              Online-Zahlungsdienstleistungen und verpflichtet sich zur
              Einhaltung strenger Sicherheitsstandards, um Ihre persönlichen
              Daten zu schützen. Unser Webshop erhält keine Zugriff auf Ihre
              vollständigen Kreditkarten- oder Bankkontodaten.
            </p>
            <p>
              Cookies von Stripe: Zusätzlich setzt Stripe möglicherweise
              Cookies, wenn Sie die Zahlungsseite aufrufen. Diese Cookies werden
              verwendet, um die Sicherheit der Zahlungsabwicklung zu erhöhen und
              Betrugsversuche zu erkennen. Die durch Cookies gesammelten
              Informationen können auch dazu genutzt werden, den Zahlungsprozess
              zu optimieren und zu personalisieren. Weitere Informationen zu den
              von Stripe verwendeten Cookies finden Sie in der{' '}
              <a href='https://stripe.com/de/legal/cookies-policy'>
                Cookie-Richtlinie von Stripe
              </a>
              .
            </p>
          </div>
          <div id='log-files'>
            <h4>V. Bereitstellung der Website und Erstellung von Logfiles</h4>
            <h5>1. Beschreibung und Umfang der Datenverarbeitung</h5>
            <p>
              Bei jedem Aufruf unserer Internetseite erfasst unser System
              automatisiert Daten und Informationen vom Computersystem des
              aufrufenden Rechners.<br></br>
              Folgende Daten werden hierbei erhoben:{' '}
            </p>
            <p>
              (1) Informationen über den Browsertyp und die verwendete Version{' '}
              <br></br>(2) Das Betriebssystem des Nutzers <br></br>(3) Den
              Internet-Service-Provider des Nutzers <br></br>(4) Die IP-Adresse
              des Nutzers <br></br>(5) Datum und Uhrzeit des Zugriffs <br></br>
              (6) Websites, von denen das System des Nutzers auf unsere
              Internetseite gelangt <br></br>(7) Websites, die vom System des
              Nutzers über unsere Website aufgerufen werden
            </p>
            <p>
              Die Daten werden ebenfalls in den Logfiles unseres Systems
              gespeichert. Eine Speicherung dieser Daten zusammen mit anderen
              personenbezogenen Daten des Nutzers findet nicht statt.
            </p>
            <h5>2. Rechtsgrundlage für die Datenverarbeitung </h5>
            <p>
              Rechtsgrundlage für die vorübergehende Speicherung der Daten und
              der Logfiles ist Art. 6 Abs. 1 lit. f DSGVO.
            </p>
            <h5>3. Zweck der Datenverarbeitung</h5>
            <p>
              Die vorübergehende Speicherung der IP-Adresse durch das System ist
              notwendig, um eine Auslieferung der Website an den Rechner des
              Nutzers zu ermöglichen. Hierfür muss die IP-Adresse des Nutzers
              für die Dauer der Sitzung gespeichert bleiben.
            </p>
            <p>
              Die Speicherung in Logfiles erfolgt, um die Funktionsfähigkeit der
              Website sicherzustellen. Zudem dienen uns die Daten zur
              technischen Optimierung der Website und zur Sicherstellung der
              Sicherheit unserer informationstechnischen Systeme. Eine
              Auswertung der Daten zu Marketingzwecken findet in diesem
              Zusammenhang nicht statt.
            </p>
            <p>
              In diesen Zwecken liegt auch unser berechtigtes Interesse an der
              Datenverarbeitung nach Art. 6 Abs. 1 lit. f DSGVO.
            </p>
            <h5>4. Dauer der Speicherung</h5>
            <p>
              Die Daten werden gelöscht, sobald sie für die Erreichung des
              Zweckes ihrer Erhebung nicht mehr erforderlich sind. Im Falle der
              Erfassung der Daten zur Bereitstellung der Website ist dies der
              Fall, wenn die jeweilige Sitzung beendet ist.
            </p>
            <p>
              Im Falle der Speicherung der Daten in Logfiles ist dies nach
              spätestens sieben Tagen der Fall. Eine darüberhinausgehende
              Speicherung ist möglich. In diesem Fall werden die IP-Adressen der
              Nutzer gelöscht oder verfremdet, sodass eine Zuordnung des
              aufrufenden Clients nicht mehr möglich ist.
            </p>
            <h5>5. Widerspruchs- und Beseitigungsmöglichkeit</h5>
            <p>
              Die Erfassung der Daten zur Bereitstellung der Website und die
              Speicherung der Daten in Logfiles ist für den Betrieb der
              Internetseite zwingend erforderlich. Es besteht folglich seitens
              des Nutzers keine Widerspruchsmöglichkeit.
            </p>
          </div>
          <div id='cookies'>
            <h4>VI. Verwendung von Cookies</h4>
            <h5>1. Beschreibung und Umfang der Datenverarbeitung</h5>
            <p>
              Unsere Webseite verwendet Cookies. Bei Cookies handelt es sich um
              Textdateien, die im Internetbrowser bzw. vom Internetbrowser auf
              dem Computersystem des Nutzers gespeichert werden. Ruft ein Nutzer
              eine Website auf, so kann ein Cookie auf dem Betriebssystem des
              Nutzers gespeichert werden. Dieser Cookie enthält eine
              charakteristische Zeichenfolge, die eine eindeutige
              Identifizierung des Browsers beim erneuten Aufrufen der Website
              ermöglicht.
            </p>
            <p>
              Bei Aufruf unserer Website wird der Nutzer über die Verwendung von
              Cookies zu Analysezwecken informiert und seine Einwilligung zur
              Verarbeitung der in diesem Zusammenhang verwendeten
              personenbezogenen Daten eingeholt. In diesem Zusammenhang erfolgt
              auch ein Hinweis auf diese Datenschutzerklärung.
            </p>
            <h5>2. Rechtsgrundlage für die Datenverarbeitung</h5>
            <p>
              Die Rechtsgrundlage für die Verarbeitung personenbezogener Daten
              unter Verwendung von technisch erforderlichen Cookies i.S.d. § 25
              Abs. 2 TTDSG ist Art. 6 Abs. 1 lit. f DSGVO.
            </p>
            <p>
              Die Rechtsgrundlage für die Verarbeitung personenbezogener Daten
              unter Verwendung von Cookies zu Analysezwecken ist bei Vorliegen
              einer diesbezüglichen Einwilligung des Nutzers Art. 6 Abs. 1 lit.
              a DSGVO.
            </p>
            <p>
              Die Rechtsgrundlage für die Verarbeitung personenbezogener Daten
              unter Verwendung technisch notwendiger Cookies ist andernfalls
              Art. 6 Abs. 1 lit. f DSGVO.
            </p>
            <h5>3. Zweck der Datenverarbeitung</h5>
            <p>
              Der Zweck der Verwendung technisch notwendiger Cookies ist, die
              Nutzung von Websites für die Nutzer zu ermöglichen. Einige
              Funktionen unserer Internetseite können ohne den Einsatz von
              Cookies nicht angeboten werden. Für diese ist es erforderlich,
              dass der Browser auch nach einem Seitenwechsel wiedererkannt wird.
            </p>
            <p>
              Lokaler Speicher: Informationen zu Artikeln, die Sie in den
              Warenkorb legen, sowie Ihre Login-Informationen werden im lokalen
              Speicher Ihres Geräts abgelegt. Dies ermöglicht es uns, dass Ihre
              Eingaben und Auswahlmöglichkeiten über die Browsersitzung hinweg
              erhalten bleiben und Sie bei Ihrem nächsten Besuch auf unserer
              Seite schneller navigieren können, ohne erneut alle Daten eingeben
              zu müssen.
            </p>
            <p>
              Die Verwendung der Analyse-Cookies erfolgt zu dem Zweck, die
              Qualität unserer Website und ihre Inhalte zu verbessern. Durch die
              Analyse-Cookies erfahren wir, wie die Website genutzt wird und
              können so unser Angebot stetig optimieren. Die Analyse des
              Surfverhaltens unserer Nutzer ist im Abschnitt{' '}
              <a href='#matomo'>VII. Webanalyse durch Matomo</a> erläutert.
            </p>

            <p>
              In diesen Zwecken liegt auch unser berechtigtes Interesse in der
              nachfolgenden Verarbeitung der personenbezogenen Daten nach Art. 6
              Abs. 1 lit. f DSGVO.
            </p>
            <h5>
              4. Dauer der Speicherung, Widerspruchs- und
              Beseitigungsmöglichkeit
            </h5>
            <p>
              Cookies werden auf dem Rechner des Nutzers gespeichert und von
              diesem an unsere Seite übermittelt. Daher haben Sie als Nutzer
              auch die volle Kontrolle über die Verwendung von Cookies. Durch
              eine Änderung der Einstellungen in Ihrem Internetbrowser können
              Sie die Speicherung von Cookies deaktivieren oder einschränken.
              Bereits gespeicherte Cookies können jederzeit gelöscht werden.
              Dies kann auch automatisiert erfolgen. Werden Cookies für unsere
              Website deaktiviert, können möglicherweise nicht mehr alle
              Funktionen der Website vollumfänglich genutzt werden.
            </p>
          </div>
          <div id='newsletter'>
            <h4>VII. Newsletter</h4>
            <h5>1. Beschreibung und Umfang der Datenverarbeitung</h5>
            <p>
              Angemeldeten Nutzern besteht auf unserer Internetseite die
              Möglichkeit einen kostenfreien Newsletter zu abonnieren. Diese
              Einverständnis wird mit folgenden Daten aus Ihrem Nutzerprofil
              verknüpft, um Ihnen personalisierte Newsletter zukommen zu lassen.
            </p>
            <p>
              <ul>
                <li>Vorname</li>
                <li>Name</li>
                <li>E-Mail Adresse</li>
                <li>Geburtstag</li>
              </ul>
            </p>
            <p>Zudem werden folgende Daten bei der Anmeldung erhoben:</p>
            <p>
              <ul>
                <li>
                  Datum und Uhrzeit der An- bzw. Abmeldung des Newsletters
                </li>
              </ul>
            </p>
            <p>
              Für die Verarbeitung der Daten wird im Rahmen des Anmeldevorgangs
              Ihre Einwilligung eingeholt und auf diese Datenschutzerklärung
              verwiesen.
            </p>
            <p>
              Es erfolgt im Zusammenhang mit der Datenverarbeitung für den
              Versand von Newslettern keine Weitergabe der Daten an Dritte. Die
              Daten werden ausschließlich für den Versand des Newsletters
              verwendet.
            </p>
            <h5>2. Rechtsgrundlage für die Datenverarbeitung</h5>
            <p>
              Rechtsgrundlage für die Verarbeitung der Daten nach Anmeldung zum
              Newsletters durch den Nutzer ist bei Vorliegen einer Einwilligung
              des Nutzers Art. 6 Abs. 1 lit. a DSGVO.
            </p>
            <h5>3. Zweck der Datenverarbeitung</h5>
            <p>
              Die Erhebung sonstiger personenbezogener Daten im Rahmen des
              Anmeldevorgangs dient dazu, einen Missbrauch der Dienste oder der
              verwendeten E-Mail-Adresse zu verhindern.
            </p>
            <h5>4. Dauer der Speicherung</h5>
            <p>
              Die Daten werden gelöscht, sobald sie für die Erreichung des
              Zweckes ihrer Erhebung nicht mehr erforderlich sind. Nach
              Abmeldung des Newsletter bleiben ihre An- und Abmeldungen für den
              Newsletter für 1 Jahr gespeichert, um den Anmeldeverlauf
              nachweisen zu können. Mit Löschung ihres Nutzerkontos, werden
              zugehörige An- und Abmeldungen für den Newsletter mit sofortiger
              Wirkung gelöscht.
            </p>
            <h5>5. Widerspruchs- und Beseitigungsmöglichkeit</h5>
            <p>
              Das Abonnement des Newsletters kann durch den betroffenen Nutzer
              jederzeit gekündigt werden. Zu diesem Zweck findet sich in jedem
              Newsletter ein entsprechender Link.
            </p>
          </div>
          <div id='registration'>
            <h4>VIII. Registrierung</h4>
            <h5>1. Beschreibung und Umfang der Datenverarbeitung</h5>
            <p>
              Auf unserer Internetseite bieten wir Nutzern die Möglichkeit, sich
              unter Angabe personenbezogener Daten zu registrieren. Die Daten
              werden dabei in eine Eingabemaske eingegeben und an uns
              übermittelt und gespeichert. Eine Weitergabe der Daten an Dritte
              findet nicht statt. Folgende Daten werden im Rahmen des
              Registrierungsprozesses erhoben:
            </p>
            <p>
              <ul>
                <li>E-Mail Adresse (erforderlich)</li>
                <li>Vorname, Nachname (optional via Profilseite)</li>
                <li>Geburtstag (optional via Profilseite)</li>
              </ul>
            </p>
            <p>
              Im Zeitpunkt der Absendung der Nachricht werden zudem folgende
              Daten gespeichert:
            </p>
            <p>
              <ul>
                <li>IP-Adresse des Nutzers</li>
                <li>Datum und Uhrzeit der Registrierung</li>
              </ul>
            </p>
            <p>
              Im Rahmen des Registrierungsprozesses wird eine Einwilligung des
              Nutzers zur Verarbeitung dieser Daten eingeholt.
            </p>
            <h5>2. Rechtsgrundlage für die Datenverarbeitung</h5>
            <p>
              Rechtsgrundlage für die Verarbeitung der Daten, die im Zuge der
              Registrierung erhoben werden, ist die Anbahnung eines
              Kaufvertrages. Art. 6 Abs. 1 lit. b DSGVO. Die sonstigen während
              des Absendevorgangs verarbeiteten personenbezogenen Daten dienen
              dazu, einen Missbrauch des Registrierungsformulars zu verhindern
              und die Sicherheit unserer informationstechnischen Systeme
              sicherzustellen.
            </p>
            <h5>4. Dauer der Speicherung</h5>
            <p>
              Die Daten werden gelöscht, sobald sie für die Erreichung des
              Zweckes ihrer Erhebung nicht mehr erforderlich sind. Dies ist für
              die während des Registrierungsvorgangs erhobenen Daten der Fall,
              wenn die Registrierung auf unserer Internetseite aufgehoben oder
              abgeändert wird. Die während des Absendevorgangs zusätzlich
              erhobenen personenbezogenen Daten werden spätestens nach einer
              Frist von 30 Tagen gelöscht.
            </p>
            <h5>5. Möglichkeit des Widerspruchs</h5>
            <p>
              Als Nutzer haben Sie die Möglichkeit, die Registrierung aufzulösen
              und somit der Verarbeitung zu widersprechen.
            </p>
          </div>
          <div id='matomo'>
            <h4>IX. Webanalyse durch Matomo</h4>
            <h5>1. Umfang der Verarbeitung personenbezogener Daten</h5>
            <p>
              Wir nutzen auf unserer Website das Open-Source-Software-Tool
              Matomo (ehemals PIWIK) zur Analyse des Surfverhaltens unserer
              Nutzer. Die Software setzt ein Cookie auf dem Rechner der Nutzer
              (zu Cookies siehe bereits oben). Werden Einzelseiten unserer
              Website aufgerufen, so werden folgende Daten gespeichert:<br></br>
            </p>
            <p>
              (1) Zwei Bytes der IP-Adresse des aufrufenden Systems des Nutzers{' '}
              <br></br>(2) Die aufgerufene Webseite <br></br>(3) Die Website,
              von der der Nutzer auf die aufgerufene Webseite gelangt ist
              (Referrer)
              <br></br>(4) Die Unterseiten, die von der aufgerufenen Webseite
              aus aufgerufen werden <br></br>(5) Die Verweildauer auf der
              Webseite <br></br>(6) Die Häufigkeit des Aufrufs der Webseite
            </p>
            <br></br>
            <p>
              Die Software läuft dabei ausschließlich auf den Servern unserer
              Webseite. Eine Speicherung der personenbezogenen Daten der Nutzer
              findet nur dort statt. Eine Weitergabe der Daten an Dritte erfolgt
              nicht.
            </p>
            <h5>
              2. Rechtsgrundlage für die Verarbeitung personenbezogener Daten
            </h5>
            <p>
              Rechtsgrundlage für die Verarbeitung der personenbezogenen Daten
              der Nutzer ist Art. 6 Abs. 1 lit. f DSGVO.
            </p>
            <h5>3. Zweck der Datenverarbeitung</h5>
            <p>
              Die Verarbeitung der personenbezogenen Daten der Nutzer ermöglicht
              uns eine Analyse des Surfverhaltens unserer Nutzer. Wir sind durch
              die Auswertung der gewonnenen Daten in der Lage, Informationen
              über die Nutzung der einzelnen Komponenten unserer Webseite
              zusammenzustellen. Dies hilft uns dabei unsere Webseite und deren
              Nutzerfreundlichkeit stetig zu verbessern. In diesen Zwecken liegt
              auch unser berechtigtes Interesse in der Verarbeitung der Daten
              nach Art. 6 Abs. 1 lit. f DSGVO. Durch die Anonymisierung der
              IP-Adresse wird dem Interesse der Nutzer an deren Schutz
              personenbezogener Daten hinreichend Rechnung getragen.
            </p>
            <h5>4. Dauer der Speicherung</h5>
            <p>
              Die Daten werden gelöscht, sobald sie für unsere
              Aufzeichnungszwecke nicht mehr benötigt werden. In unserem Fall
              ist dies nach 365 Tagen der Fall.
            </p>
            <h5>5. Widerspruchs- und Beseitigungsmöglichkeit</h5>
            <p>
              Cookies werden auf dem Rechner des Nutzers gespeichert und von
              diesem an unserer Seite übermittelt. Daher haben Sie als Nutzer
              auch die volle Kontrolle über die Verwendung von Cookies. Durch
              eine Änderung der Einstellungen in Ihrem Internetbrowser können
              Sie die Speicherung von Cookies deaktivieren oder einschränken.
              Bereits gespeicherte Cookies können jederzeit gelöscht werden.
              Dies kann auch automatisiert erfolgen. Werden Cookies für unsere
              Website deaktiviert, können möglicherweise nicht mehr alle
              Funktionen der Website vollumfänglich genutzt werden. <br></br>
              Nähere Informationen zu den Privatsphäreeinstellungen der Matomo
              Software finden Sie unter folgendem Link:{' '}
              <a href='https://matomo.org/docs/privacy/'>
                https://matomo.org/docs/privacy/
              </a>
              .
            </p>
            <div id='user-rights'>
              <h4>X. Rechte der betroffenen Person</h4>
              <p>
                Werden personenbezogene Daten von Ihnen verarbeitet, sind Sie
                Betroffener i.S.d. DSGVO und es stehen Ihnen folgende Rechte
                gegenüber dem Verantwortlichen zu:
              </p>
              <h5>1. Auskunftsrecht</h5>
              <p>
                Sie können von dem Verantwortlichen eine Bestätigung darüber
                verlangen, ob personenbezogene Daten, die Sie betreffen, von uns
                verarbeitet werden.
              </p>
              <p>
                Liegt eine solche Verarbeitung vor, können Sie von dem
                Verantwortlichen über folgende Informationen Auskunft verlangen:
              </p>
              <p>
                (1) die Zwecke, zu denen die personenbezogenen Daten verarbeitet
                werden; <br></br>(2) die Kategorien von personenbezogenen Daten,
                welche verarbeitet werden; <br></br>(3) die Empfänger bzw. die
                Kategorien von Empfängern, gegenüber denen die Sie betreffenden
                personenbezogenen Daten offengelegt wurden oder noch offengelegt
                werden; <br></br>(4) die geplante Dauer der Speicherung der Sie
                betreffenden personenbezogenen Daten oder, falls konkrete
                Angaben hierzu nicht möglich sind, Kriterien für die Festlegung
                der Speicherdauer; <br></br>(5) das Bestehen eines Rechts auf
                Berichtigung oder Löschung der Sie betreffenden
                personenbezogenen Daten, eines Rechts auf Einschränkung der
                Verarbeitung durch den Verantwortlichen oder eines
                Widerspruchsrechts gegen diese Verarbeitung; <br></br>(6) das
                Bestehen eines Beschwerderechts bei einer Aufsichtsbehörde;{' '}
                <br></br>(7) alle verfügbaren Informationen über die Herkunft
                der Daten, wenn die personenbezogenen Daten nicht bei der
                betroffenen Person erhoben werden; <br></br>(8) das Bestehen
                einer automatisierten Entscheidungsfindung einschließlich
                Profiling gemäß Art. 22 Abs. 1 und 4 DSGVO und – zumindest in
                diesen Fällen – aussagekräftige Informationen über die
                involvierte Logik sowie die Tragweite und die angestrebten
                Auswirkungen einer derartigen Verarbeitung für die betroffene
                Person.
              </p>
              <p>
                Ihnen steht das Recht zu, Auskunft darüber zu verlangen, ob die
                Sie betreffenden personenbezogenen Daten in ein Drittland oder
                an eine internationale Organisation übermittelt werden. In
                diesem Zusammenhang können Sie verlangen, über die geeigneten
                Garantien gem. Art. 46 DSGVO im Zusammenhang mit der
                Übermittlung unterrichtet zu werden.
              </p>
              <h5>2. Recht auf Berichtigung</h5>
              <p>
                Sie haben ein Recht auf Berichtigung und/oder Vervollständigung
                gegenüber dem Verantwortlichen, sofern die verarbeiteten
                personenbezogenen Daten, die Sie betreffen, unrichtig oder
                unvollständig sind. Der Verantwortliche hat die Berichtigung
                unverzüglich vorzunehmen.
              </p>
              <h5>3. Recht auf Einschränkung der Verarbeitung</h5>
              <p>
                Unter den folgenden Voraussetzungen können Sie die Einschränkung
                der Verarbeitung der Sie betreffenden personenbezogenen Daten
                verlangen:
              </p>
              <p>
                wenn Sie die Richtigkeit der Sie betreffenden personenbezogenen
                für eine Dauer bestreiten, die es dem Verantwortlichen
                ermöglicht, die Richtigkeit der personenbezogenen Daten zu
                überprüfen; <br></br>(2) die Verarbeitung unrechtmäßig ist und
                Sie die Löschung der personenbezogenen Daten ablehnen und
                stattdessen die Einschränkung der Nutzung der personenbezogenen
                Daten verlangen; <br></br>(3) der Verantwortliche die
                personenbezogenen Daten für die Zwecke der Verarbeitung nicht
                länger benötigt, Sie diese jedoch zur Geltendmachung, Ausübung
                oder Verteidigung von Rechtsansprüchen benötigen, oder <br></br>
                (4) wenn Sie Widerspruch gegen die Verarbeitung gemäß Art. 21
                Abs. 1 DSGVO eingelegt haben und noch nicht feststeht, ob die
                berechtigten Gründe des Verantwortlichen gegenüber Ihren Gründen
                überwiegen.
              </p>
              <p>
                Wurde die Verarbeitung der Sie betreffenden personenbezogenen
                Daten eingeschränkt, dürfen diese Daten – von ihrer Speicherung
                abgesehen – nur mit Ihrer Einwilligung oder zur Geltendmachung,
                Ausübung oder Verteidigung von Rechtsansprüchen oder zum Schutz
                der Rechte einer anderen natürlichen oder juristischen Person
                oder aus Gründen eines wichtigen öffentlichen Interesses der
                Union oder eines Mitgliedstaats verarbeitet werden.
              </p>
              <p>
                Wurde die Einschränkung der Verarbeitung nach den o.g.
                Voraussetzungen eingeschränkt, werden Sie von dem
                Verantwortlichen unterrichtet bevor die Einschränkung aufgehoben
                wird.
              </p>
              <h5>4. Recht auf Löschung</h5>
              <p>a) Löschungspflicht</p>
              <p>
                Sie können von dem Verantwortlichen verlangen, dass die Sie
                betreffenden personenbezogenen Daten unverzüglich gelöscht
                werden, und der Verantwortliche ist verpflichtet, diese Daten
                unverzüglich zu löschen, sofern einer der folgenden Gründe
                zutrifft:
              </p>
              <p>
                Die Sie betreffenden personenbezogenen Daten sind für die
                Zwecke, für die sie erhoben oder auf sonstige Weise verarbeitet
                wurden, nicht mehr notwendig. <br></br>(2) Sie widerrufen Ihre
                Einwilligung, auf die sich die Verarbeitung gem. Art. 6 Abs. 1
                lit. a oder Art. 9 Abs. 2 lit. a DSGVO stützte, und es fehlt an
                einer anderweitigen Rechtsgrundlage für die Verarbeitung.{' '}
                <br></br>(3) Sie legen gem. Art. 21 Abs. 1 DSGVO Widerspruch
                gegen die Verarbeitung ein und es liegen keine vorrangigen
                berechtigten Gründe für die Verarbeitung vor, oder Sie legen
                gem. Art. 21 Abs. 2 DSGVO Widerspruch gegen die Verarbeitung
                ein. <br></br>(4) Die Sie betreffenden personenbezogenen Daten
                wurden unrechtmäßig verarbeitet. <br></br>(5) Die Löschung der
                Sie betreffenden personenbezogenen Daten ist zur Erfüllung einer
                rechtlichen Verpflichtung nach dem Unionsrecht oder dem Recht
                der Mitgliedstaaten erforderlich, dem der Verantwortliche
                unterliegt. <br></br>(6) Die Sie betreffenden personenbezogenen
                Daten wurden in Bezug auf angebotene Dienste der
                Informationsgesellschaft gemäß Art. 8 Abs. 1 DSGVO erhoben.
              </p>
              <p>b) Information an Dritte</p>
              <p>
                Hat der Verantwortliche die Sie betreffenden personenbezogenen
                Daten öffentlich gemacht und ist er gem. Art. 17 Abs. 1 DSGVO zu
                deren Löschung verpflichtet, so trifft er unter Berücksichtigung
                der verfügbaren Technologie und der Implementierungskosten
                angemessene Maßnahmen, auch technischer Art, um für die
                Datenverarbeitung Verantwortliche, die die personenbezogenen
                Daten verarbeiten, darüber zu informieren, dass Sie als
                betroffene Person von ihnen die Löschung aller Links zu diesen
                personenbezogenen Daten oder von Kopien oder Replikationen
                dieser personenbezogenen Daten verlangt haben.
              </p>
              <p>c) Ausnahmen</p>
              <p>
                Das Recht auf Löschung besteht nicht, soweit die Verarbeitung
                erforderlich ist
              </p>
              <p>
                (1) zur Ausübung des Rechts auf freie Meinungsäußerung und
                Information; <br></br>(2) zur Erfüllung einer rechtlichen
                Verpflichtung, die die Verarbeitung nach dem Recht der Union
                oder der Mitgliedstaaten, dem der Verantwortliche unterliegt,
                erfordert, oder zur Wahrnehmung einer Aufgabe, die im
                öffentlichen Interesse liegt oder in Ausübung öffentlicher
                Gewalt erfolgt, die dem Verantwortlichen übertragen wurde;{' '}
                <br></br>(3) aus Gründen des öffentlichen Interesses im Bereich
                der öffentlichen Gesundheit gemäß Art. 9 Abs. 2 lit. h und i
                sowie Art. 9 Abs. 3 DSGVO; <br></br>(4) für im öffentlichen
                Interesse liegende Archivzwecke, wissenschaftliche oder
                historische Forschungszwecke oder für statistische Zwecke gem.
                Art. 89 Abs. 1 DSGVO, soweit das unter Abschnitt a) genannte
                Recht voraussichtlich die Verwirklichung der Ziele dieser
                Verarbeitung unmöglich macht oder ernsthaft beeinträchtigt, oder{' '}
                <br></br>(5) zur Geltendmachung, Ausübung oder Verteidigung von
                Rechtsansprüchen.
              </p>
              <h5>5. Recht auf Unterrichtung</h5>
              <p>
                Haben Sie das Recht auf Berichtigung, Löschung oder
                Einschränkung der Verarbeitung gegenüber dem Verantwortlichen
                geltend gemacht, ist dieser verpflichtet, allen Empfängern,
                denen die Sie betreffenden personenbezogenen Daten offengelegt
                wurden, diese Berichtigung oder Löschung der Daten oder
                Einschränkung der Verarbeitung mitzuteilen, es sei denn, dies
                erweist sich als unmöglich oder ist mit einem
                unverhältnismäßigen Aufwand verbunden.
              </p>
              <p>
                Ihnen steht gegenüber dem Verantwortlichen das Recht zu, über
                diese Empfänger unterrichtet zu werden.
              </p>
              <h5>6. Recht auf Datenübertragbarkeit</h5>
              <p>
                Sie haben das Recht, die Sie betreffenden personenbezogenen
                Daten, die Sie dem Verantwortlichen bereitgestellt haben, in
                einem strukturierten, gängigen und maschinenlesbaren Format zu
                erhalten. Außerdem haben Sie das Recht diese Daten einem anderen
                Verantwortlichen ohne Behinderung durch den Verantwortlichen,
                dem die personenbezogenen Daten bereitgestellt wurden, zu
                übermitteln, sofern
              </p>
              <p>
                (1) die Verarbeitung auf einer Einwilligung gem. Art. 6 Abs. 1
                lit. a DSGVO oder Art. 9 Abs. 2 lit. a DSGVO oder auf einem
                Vertrag gem. Art. 6 Abs. 1 lit. b DSGVO beruht und
                <br></br>(2) die Verarbeitung mithilfe automatisierter Verfahren
                erfolgt.
              </p>
              <p>
                In Ausübung dieses Rechts haben Sie ferner das Recht, zu
                erwirken, dass die Sie betreffenden personenbezogenen Daten
                direkt von einem Verantwortlichen einem anderen Verantwortlichen
                übermittelt werden, soweit dies technisch machbar ist.
                Freiheiten und Rechte anderer Personen dürfen hierdurch nicht
                beeinträchtigt werden.
              </p>
              <p>
                Das Recht auf Datenübertragbarkeit gilt nicht für eine
                Verarbeitung personenbezogener Daten, die für die Wahrnehmung
                einer Aufgabe erforderlich ist, die im öffentlichen Interesse
                liegt oder in Ausübung öffentlicher Gewalt erfolgt, die dem
                Verantwortlichen übertragen wurde.
              </p>
              <h5>7. Widerspruchsrecht</h5>
              <p>
                Sie haben das Recht, aus Gründen, die sich aus ihrer besonderen
                Situation ergeben, jederzeit gegen die Verarbeitung der Sie
                betreffenden personenbezogenen Daten, die aufgrund von Art. 6
                Abs. 1 lit. e oder f DSGVO erfolgt, Widerspruch einzulegen; dies
                gilt auch für ein auf diese Bestimmungen gestütztes Profiling.
              </p>
              <p>
                Der Verantwortliche verarbeitet die Sie betreffenden
                personenbezogenen Daten nicht mehr, es sei denn, er kann
                zwingende schutzwürdige Gründe für die Verarbeitung nachweisen,
                die Ihre Interessen, Rechte und Freiheiten überwiegen, oder die
                Verarbeitung dient der Geltendmachung, Ausübung oder
                Verteidigung von Rechtsansprüchen.
              </p>
              <p>
                Werden die Sie betreffenden personenbezogenen Daten verarbeitet,
                um Direktwerbung zu betreiben, haben Sie das Recht, jederzeit
                Widerspruch gegen die Verarbeitung der Sie betreffenden
                personenbezogenen Daten zum Zwecke derartiger Werbung
                einzulegen; dies gilt auch für das Profiling, soweit es mit
                solcher Direktwerbung in Verbindung steht.
              </p>
              <p>
                Widersprechen Sie der Verarbeitung für Zwecke der Direktwerbung,
                so werden die Sie betreffenden personenbezogenen Daten nicht
                mehr für diese Zwecke verarbeitet.
              </p>
              <p>
                Sie haben die Möglichkeit, im Zusammenhang mit der Nutzung von
                Diensten der Informationsgesellschaft – ungeachtet der
                Richtlinie 2002/58/EG – Ihr Widerspruchsrecht mittels
                automatisierter Verfahren auszuüben, bei denen technische
                Spezifikationen verwendet werden.
              </p>
              <h5>
                8. Recht auf Widerruf der datenschutzrechtlichen
                Einwilligungserklärung
              </h5>
              <p>
                Sie haben das Recht, Ihre datenschutzrechtliche
                Einwilligungserklärung jederzeit zu widerrufen. Durch den
                Widerruf der Einwilligung wird die Rechtmäßigkeit der aufgrund
                der Einwilligung bis zum Widerruf erfolgten Verarbeitung nicht
                berührt.
              </p>
              <h5>
                9. Automatisierte Entscheidung im Einzelfall einschließlich
                Profiling
              </h5>
              <p>
                Sie haben das Recht, nicht einer ausschließlich auf einer
                automatisierten Verarbeitung – einschließlich Profiling –
                beruhenden Entscheidung unterworfen zu werden, die Ihnen
                gegenüber rechtliche Wirkung entfaltet oder Sie in ähnlicher
                Weise erheblich beeinträchtigt. Dies gilt nicht, wenn die
                Entscheidung{' '}
              </p>
              <p>
                (1) für den Abschluss oder die Erfüllung eines Vertrags zwischen
                Ihnen und dem Verantwortlichen erforderlich ist,
                <br></br>(2) aufgrund von Rechtsvorschriften der Union oder der
                Mitgliedstaaten, denen der Verantwortliche unterliegt, zulässig
                ist und diese Rechtsvorschriften angemessene Maßnahmen zur
                Wahrung Ihrer Rechte und Freiheiten sowie Ihrer berechtigten
                Interessen enthalten oder
                <br></br>(3) mit Ihrer ausdrücklichen Einwilligung erfolgt.
              </p>
              <p>
                Allerdings dürfen diese Entscheidungen nicht auf besonderen
                Kategorien personenbezogener Daten nach Art. 9 Abs. 1 DSGVO
                beruhen, sofern nicht Art. 9 Abs. 2 lit. a oder g DSGVO gilt und
                angemessene Maßnahmen zum Schutz der Rechte und Freiheiten sowie
                Ihrer berechtigten Interessen getroffen wurden.
              </p>
              <p>
                Hinsichtlich der in (1) und (3) genannten Fälle trifft der
                Verantwortliche angemessene Maßnahmen, um die Rechte und
                Freiheiten sowie Ihre berechtigten Interessen zu wahren, wozu
                mindestens das Recht auf Erwirkung des Eingreifens einer Person
                seitens des Verantwortlichen, auf Darlegung des eigenen
                Standpunkts und auf Anfechtung der Entscheidung gehört.
              </p>
              <h5>10. Recht auf Beschwerde bei einer Aufsichtsbehörde</h5>
              <p>
                Unbeschadet eines anderweitigen verwaltungsrechtlichen oder
                gerichtlichen Rechtsbehelfs steht Ihnen das Recht auf Beschwerde
                bei einer Aufsichtsbehörde, insbesondere in dem Mitgliedstaat
                ihres Aufenthaltsorts, ihres Arbeitsplatzes oder des Orts des
                mutmaßlichen Verstoßes, zu, wenn Sie der Ansicht sind, dass die
                Verarbeitung der Sie betreffenden personenbezogenen Daten gegen
                die DSGVO verstößt.
              </p>
              <p>
                Die Aufsichtsbehörde, bei der die Beschwerde eingereicht wurde,
                unterrichtet den Beschwerdeführer über den Stand und die
                Ergebnisse der Beschwerde einschließlich der Möglichkeit eines
                gerichtlichen Rechtsbehelfs nach Art. 78 DSGVO.
              </p>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );

  return <BaseLayout children={content}></BaseLayout>;
}

export default DataPrivacyPage;
