import { Nav, Button, Form, Modal, Alert, Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import React, { useState } from 'react';
//import { handleErrorResponse } from '../utils/errors'; // Import from the correct path

import './LoginSignupModal.scss';
import PasswordInput from './PasswordInput';

import { useDispatch } from 'react-redux';
import { fetchUser, login, signup } from '../redux/userSlice';
import userIcon from '../assets/icons/user.png'; // Adjust the path according to where you store the image

function LoginSignupModal() {
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [subscribeToNewsletter, setSubscribeToNewsletter] = useState(false);

  // Handle change event for the checkbox
  const handleNewsletterChange = (e) => {
    setSubscribeToNewsletter(e.target.checked);
  };

  const [show, setShow] = useState(false);

  const [repeatedPassword, setRepeatedPassword] = useState('');

  const [isLoginForm, setIsLoginForm] = useState(true); // State to toggle between login and register forms
  const [errorMessages, setErrorMessages] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setShow(false);
    setErrorMessages([]);
    setEmail('');
    setPassword('');
    setRepeatedPassword('');
    setLoading(false); // just to be sure
  };
  const handleShow = () => {
    setShow(true);
    setErrorMessages([]);
    setEmail('');
    setPassword('');
    setRepeatedPassword('');
    setLoading(false); // just to be sure
  };

  // Assuming error is an object similar to the one you provided
  const handleError = (error) => {
    // Define a default message
    const defaultMessage = 'Ein Fehler ist aufgetreten.';

    // Check if error is an object and has the necessary structure
    if (error && typeof error === 'object') {
      // Extract the errors object
      const errors = error.errors;

      // Initialize an array to collect all error messages
      let allMessages = [];

      if (errors && typeof errors === 'object') {
        // Iterate over the errors object and collect messages
        Object.values(errors).forEach((messages) => {
          if (Array.isArray(messages)) {
            allMessages = allMessages.concat(messages); // Append all messages
          } else if (typeof messages === 'string') {
            allMessages.push(messages); // Handle single message
          }
        });
      }

      // If we have collected any messages, set them; otherwise, use default message
      setErrorMessages(allMessages.length > 0 ? allMessages : [defaultMessage]);
    } else {
      // If error is not an object, use default message
      setErrorMessages([defaultMessage]);
    }
  };

  const handleEmailChange = (e) => setEmail(e.target.value);
  //Note: Password change is handled within password component

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission
    setLoading(true); // Set loading state to true when the form is submitted

    // Logic for handling login/register based on isLoginForm state
    if (isLoginForm) {
      dispatch(login({ username: email, password: password }))
        .unwrap() // Unwrap the Promise returned by dispatch
        .then(() => {
          setLoading(false);
          dispatch(fetchUser());
        })
        .catch((error) => {
          setLoading(false);
          // Adapt this based on the structure of the error response
          setErrorMessages([error.message || 'Login fehlgeschlagen.']);
        });
    } else {
      //signup form
      if (repeatedPassword !== password) {
        setLoading(false);
        setErrorMessages(['Passwörter stimmen nicht überein.']);
        return;
      }
      // Dispatch signup thunk
      dispatch(signup({ email, password, subscribeToNewsletter }))
        .unwrap()
        .then(() => {
          setLoading(false);
          // Navigate or perform other actions after successful signup and login
        })
        .catch((error) => {
          setLoading(false);
          handleError(error);
        });
    }
  };

  const toggleForm = () => {
    setIsLoginForm(!isLoginForm); // Toggles between login and register forms
    setEmail('');
    setPassword('');
    setRepeatedPassword('');
    setErrorMessages([]);
    setLoading(false);
  };

  return (
    <>
      <Nav.Link onClick={handleShow}>
        <img src={userIcon} alt='Einloggen' className='nav-icon' />
      </Nav.Link>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          {isLoginForm ? (
            <Modal.Title>Bei Ihrem Konto anmelden</Modal.Title>
          ) : (
            <Modal.Title>Erstellen Sie Ihr Konto</Modal.Title>
          )}
        </Modal.Header>
        <Modal.Body>
          {isLoginForm ? (
            <form onSubmit={handleSubmit}>
              <Form.Group className='mb-3' controlId='formBasicEmail'>
                <Form.Label>E-Mail-Adresse</Form.Label>
                <Form.Control
                  type='email'
                  value={email}
                  onChange={handleEmailChange}
                  className='email-input'
                />
              </Form.Group>
              <Form.Group className='mb-3' controlId='formBasicPassword'>
                <Form.Label>Passwort</Form.Label>
                <PasswordInput
                  password={password}
                  setPassword={setPassword}
                ></PasswordInput>
              </Form.Group>
              {/* set button to deactiveated when loading */}
              <Button
                variant='primary'
                type='submit'
                onClick={handleSubmit}
                disabled={loading}
              >
                {loading ? (
                  <>
                    <Spinner
                      as='span'
                      animation='border'
                      size='sm'
                      role='status'
                      aria-hidden='true'
                      className='me-2'
                    />
                    Laden...
                  </>
                ) : (
                  'Einloggen'
                )}
              </Button>{' '}
              <Link to='/reset_password' onClick={handleClose}>
                Passwort vergessen?
              </Link>
            </form>
          ) : (
            <form onSubmit={handleSubmit}>
              <Form.Group className='mb-3' controlId='formBasicEmail'>
                <Form.Label>E-Mail-Adresse</Form.Label>
                <Form.Control
                  type='email'
                  value={email}
                  onChange={handleEmailChange}
                />
              </Form.Group>

              <Form.Group className='mb-3' controlId='formBasicPassword'>
                <Form.Label>Passwort</Form.Label>
                <PasswordInput
                  password={password}
                  setPassword={setPassword}
                ></PasswordInput>
              </Form.Group>
              <Form.Group
                className='mb-3'
                controlId='formBasicRepeatedPassword'
              >
                <Form.Label>Passwort bestätigen</Form.Label>
                <PasswordInput
                  password={repeatedPassword}
                  setPassword={setRepeatedPassword}
                ></PasswordInput>
              </Form.Group>
              <Form.Group controlId='formBasicCheckbox'>
                <Form.Check
                  type='checkbox'
                  label='Newsletter abonnieren und sparen.'
                  checked={subscribeToNewsletter}
                  onChange={handleNewsletterChange}
                />
              </Form.Group>

              <Button
                className='mt-2'
                variant='primary'
                type='submit'
                onClick={handleSubmit}
                disabled={loading}
              >
                {loading ? (
                  <>
                    <Spinner
                      as='span'
                      animation='border'
                      size='sm'
                      role='status'
                      aria-hidden='true'
                      className='me-2'
                    />
                    Laden...
                  </>
                ) : (
                  'Konto einrichten'
                )}
              </Button>
            </form>
          )}
          {errorMessages.length > 0 && (
            <Alert
              variant='danger'
              className='mt-2'
              onClose={() => setErrorMessages([])}
              dismissible
            >
              {/* Display the error messages */}
              <Alert.Heading>Fehler</Alert.Heading>
              <ul>
                {errorMessages.map((errorMessage, index) => (
                  <li key={index}>{errorMessage}</li>
                ))}
              </ul>
            </Alert>
          )}
        </Modal.Body>
        <Modal.Footer>
          {isLoginForm ? (
            <>
              <p>Sie haben kein Konto?</p>
              <Button variant='secondary' onClick={toggleForm}>
                Jetzt erstellen
              </Button>
            </>
          ) : (
            <>
              <p>Sie haben ein Konto?</p>
              <Button variant='secondary' onClick={toggleForm}>
                Anmelden
              </Button>
            </>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default LoginSignupModal;
