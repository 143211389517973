import BaseLayout from '../layouts/BaseLayout';
import { Container, Row, Col } from 'react-bootstrap';

function ImpressumPage() {
  const content = (
    <Container>
      <Row>
        <Col md={6} xs={12}>
          <h3 className='h3'>Impressum</h3>
          <h4 className='h4'>miapapeteria</h4>
          <p>
            <strong>Anschrift des Kleinunternehmens</strong>
          </p>
          <p>
            Mia Hoffmeister <br></br>Heuwaagstraße 7<br></br>91054 Erlangen
            <br></br>Deutschland
          </p>
          <br></br>
          <p>
            <strong>Telefon:</strong> +49 1573 7904180<br></br>
            <strong>Email:</strong> info@miapapeteria.de
          </p>
        </Col>
        <Col md={6} xs={12}>
          <strong>Schlichtungsstelle</strong>
          <br></br> Online-Streitbeilegung gemäß Art. 14 Abs. 1 ODR-VO: Die
          Europäische Kommission stellt eine Plattform zur
          Online-Streitbeilegung (OS) bereit, die Sie unter {'  '}
          <a href='https://ec.europa.eu/consumers/odr'>
            https://ec.europa.eu/consumers/odr
          </a>{' '}
          finden. <br></br>
          <br></br>Wir sind immer bemüht, eventuelle Meinungsverschiedenheiten
          aus unserem Vertrag einvernehmlich beizulegen. Darüber hinaus haben
          wir uns entschieden, nicht an einem Schlichtungsverfahren
          teilzunehmen. Hierzu sind wir auch nicht verpflichtet.
          <br></br>Unsere Mail Adresse lautet info@miapapeteria.com
          <br></br>
          <br></br>
          <strong>Haftungshinweis</strong>
          <br></br>
          Soweit diese Website externe Verknüpfungen (Hyperlinks) zu Websites
          Dritter enthält, unterliegen diese verlinkten Websites ausschließlich
          der inhaltlichen Verantwortung des jeweiligen Betreibers. Miapapeteria
          hat vor Einrichtung sämtlicher Links die Inhalte der fremden
          Zielseiten auf etwaige Rechtsverstöße überprüft. Gleichwohl hat
          Miapapeteria keinen Einfluss auf die inhaltliche Gestaltung der
          Zielseiten, so dass Miapapeteria sich die Inhalte der verlinkten
          Seiten ausdrücklich nicht zu eigen macht. Miapapeteria nimmt außerdem
          in regelmäßigen Abständen eine Kontrolle der verlinkten Seiten vor und
          wird bei Kenntniserlangung von einem Rechtsverstoß auf einer
          verlinkten Seite den betreffenden Link unverzüglich entfernen.
        </Col>
      </Row>
    </Container>
  );

  return <BaseLayout children={content}></BaseLayout>;
}

export default ImpressumPage;
