import React from 'react';
import ProductList from '../components/ProductList';
import BaseLayout from '../layouts/BaseLayout';

const HomePage = () => {
  const content = (
    <>
      <h1>Alle Produkte</h1>
      <ProductList />
    </>
  );

  return <BaseLayout children={content}></BaseLayout>;
};

export default HomePage;
