import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal, Form, Alert } from 'react-bootstrap';
import { changePassword } from '../redux/userSlice'; // Adjust import path as needed
import PasswordInput from './PasswordInput';

const ChangePasswordForm = () => {
  const [showModal, setShowModal] = useState(false);
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [errors, setErrors] = useState({});
  const dispatch = useDispatch();

  const { isChangingPassword, changePasswordSuccessMessage } = useSelector(
    (state) => state.user
  );

  // Reset form fields and errors
  const resetForm = () => {
    setOldPassword('');
    setNewPassword('');
    setErrors({});
  };

  // Enhanced onHide handler to also reset form status
  const handleModalClose = () => {
    setShowModal(false);
    resetForm();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(changePassword({ oldPassword, newPassword }))
      .unwrap()
      .then(() => {
        resetForm();
        setShowModal(false); // Close the modal on success
      })
      .catch((actionError) => {
        // Handle multiple errors
        if (actionError && typeof actionError === 'object') {
          setErrors(actionError);
        }
      });
  };

  return (
    <>
      <Button variant='primary' onClick={() => setShowModal(true)}>
        Passwort ändern
      </Button>

      <Modal show={showModal} onHide={handleModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Neues Passwort festlegen</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmit}>
          <Modal.Body>
            {changePasswordSuccessMessage && (
              <Alert variant='success'>{changePasswordSuccessMessage}</Alert>
            )}

            <Form.Group className='mb-3'>
              <Form.Label>Altes Passwort</Form.Label>
              <PasswordInput
                placeholder='Bestätigen Sie ihr Passwort'
                password={oldPassword}
                setPassword={setOldPassword}
                isInvalid={!!errors.old_password}
                errorMessage={errors.old_password ? errors.old_password[0] : ''}
              />
            </Form.Group>

            <Form.Group className='mb-3'>
              <Form.Label>Neues Passwort</Form.Label>
              <PasswordInput
                type='password'
                placeholder='Neues Passwort eingeben'
                password={newPassword}
                setPassword={setNewPassword}
                isInvalid={!!errors.new_password}
                errorMessage={errors.new_password ? errors.new_password[0] : ''}
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' onClick={handleModalClose}>
              Abbrechen
            </Button>
            <Button
              type='submit'
              variant='primary'
              disabled={isChangingPassword}
            >
              {isChangingPassword
                ? 'Passwort wird geändert...'
                : 'Passwort ändern'}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default ChangePasswordForm;
