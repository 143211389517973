import axios from 'axios';
import { API_URL } from './config';

const axiosInstance = axios.create({
  baseURL: API_URL, // Replace with your desired default URL
  timeout: 3000, // Set the timeout for requests (in milliseconds)
  headers: {
    'Access-Control-Allow-Headers': 'Content-Type',
    'Content-Type': 'application/json' // Set default content type
  }
});

// Add Auth-Token later like so:
// axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;

// const setAuthToken = (token) => {
//   if (token) {
//     // Set the token to the Authorization header in Axios defaults
//     axiosInstance.defaults.headers.common['Authorization'] = `Token ${token}`;
//     // Store the token in local storage for future use
//     localStorage.setItem('authToken', token);
//   }
// };

// const deleteAuthToken = () => {
//   delete axiosInstance.defaults.headers.common['Authorization'];
//   localStorage.removeItem('authToken');
// };

// export { setAuthToken, deleteAuthToken };
export default axiosInstance;
