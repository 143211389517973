import axiosInstance from '../axiosConfig';
import { Button, Form, Alert, Spinner } from 'react-bootstrap';
import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { handleErrorResponse } from '../utils/errors'; // Import from the correct path

import './LoginSignupModal.scss';
import PasswordInput from './PasswordInput';

const SetNewPasswordForm = () => {
  const [searchParams] = useSearchParams(); // Accessing the token from the URL params
  const token = searchParams.get('token'); // "token"
  const [password, setPassword] = useState('');
  const [repeatedPassword, setRepeatedPassword] = useState('');
  const [errorMessages, setErrorMessages] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission
    setLoading(true);

    if (repeatedPassword === password) {
      axiosInstance
        .post('users/api/password_reset/confirm/', {
          password: password,
          token: token
        })
        .then(() => {
          setSuccessMessage(`Passwort erfolgreich zurückgesetzt!`);
          setErrorMessages([]);
          setLoading(false);
        })
        .catch(function (error) {
          setLoading(false);
          handleErrorResponse(error, setErrorMessages);
        });
    } else {
      setLoading(false);
      setErrorMessages(['Passwörter stimmen nicht überein.']);
    }
  };

  return (
    <>
      <h1>Lege ein neues Passwort fest</h1>
      <p>Verwende ein starkes Passwort mit mindestens 8 Zeichen.</p>
      <form onSubmit={handleSubmit}>
        <Form.Group className='mb-3' controlId='formBasicPassword'>
          <Form.Label>Passwort</Form.Label>
          <PasswordInput
            password={password}
            setPassword={setPassword}
          ></PasswordInput>
        </Form.Group>
        <Form.Group className='mb-3' controlId='formBasicRepeatedPassword'>
          <Form.Label>Passwort bestätigen</Form.Label>
          <PasswordInput
            password={repeatedPassword}
            setPassword={setRepeatedPassword}
          ></PasswordInput>
        </Form.Group>

        <Button
          variant='primary'
          type='submit'
          onClick={handleSubmit}
          disabled={loading}
        >
          {loading ? (
            <>
              <Spinner
                as='span'
                animation='border'
                size='sm'
                role='status'
                aria-hidden='true'
                className='me-2'
              />
              Laden...
            </>
          ) : (
            'Konto einrichten'
          )}
        </Button>
      </form>
      {successMessage && (
        <Alert className='mt-2' variant='success'>
          {successMessage}
        </Alert>
      )}
      {errorMessages.length > 0 && (
        <Alert
          variant='danger'
          className='mt-2'
          onClose={() => setErrorMessages([])}
          dismissible
        >
          {/* Display the error messages */}
          <Alert.Heading>Fehler</Alert.Heading>
          <ul>
            {errorMessages.map((errorMessage, index) => (
              <li key={index}>{errorMessage}</li>
            ))}
          </ul>
        </Alert>
      )}
    </>
  );
};

export default SetNewPasswordForm;
