import { useDispatch } from 'react-redux';
import { logout } from '../utils/logout';

const useLogout = (navigate) => {
  const dispatch = useDispatch();

  const handleLogout = () => {
    logout(dispatch, navigate);
  };

  return handleLogout;
};

export default useLogout;
