import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const BaseLayout = ({ children }) => {
  return (
    <Container>
      <Row>
        {/* Main Content */}
        <Col className='p-3'>{children}</Col>
      </Row>
    </Container>
  );
};

export default BaseLayout;
