import React, { useEffect, useState, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import BaseLayout from '../layouts/BaseLayout';
import ProductDetailComponent from '../components/ProductDetail';
import { withRetryOnUnauthorized } from '../utils/withRetryOnUnauthorized';
import { fetchProduct, fetchPriceByProduct } from '../utils/api';
import { Button } from 'react-bootstrap';
import config from '../config';
import { FaArrowLeft } from 'react-icons/fa';

function ProductDetailPage() {
  const navigate = useNavigate();
  const { productId } = useParams();
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchPriceByProductWithRetry = useMemo(
    () => withRetryOnUnauthorized(fetchPriceByProduct),
    []
  );
  const fetchProductWithRetry = useMemo(
    () => withRetryOnUnauthorized(fetchProduct),
    []
  );

  const handleRedirect = () => {
    navigate('/shop');
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const product = await fetchProductWithRetry(productId);
        const prices = await fetchPriceByProductWithRetry(product.id);
        setProduct({ ...product, prices });
      } catch (error) {
        setProduct(null);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [productId, fetchPriceByProductWithRetry, fetchProductWithRetry]);

  if (loading) {
    return (
      <BaseLayout>
        <div>Lade Produkt...</div>
      </BaseLayout>
    );
  }

  return (
    <BaseLayout>
      <Button variant='secondary' onClick={handleRedirect}>
        <FaArrowLeft /> Zu den Produkten
      </Button>
      {product ? (
        <ProductDetailComponent product={product} />
      ) : (
        <>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <img
              src={config.somethingWentWrong}
              alt='something went wrong'
              style={{ maxWidth: '100%', height: 'auto', maxHeight: '500px' }}
            />
          </div>
        </>
      )}
    </BaseLayout>
  );
}

export default ProductDetailPage;
