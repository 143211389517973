import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import miaImg from '../assets/images/mia.jpg';

const AboutSection = () => {
  return (
    <Container>
      <Row className='align-items-center  pb-3 pt-3'>
        <Col xs={12} md={6}>
          <img
            src={miaImg}
            alt='mia'
            style={{
              width: '100%',
              maxWidth: '100%',
              height: 'auto',
              borderRadius: '20px'
            }}
          />
        </Col>

        <Col xs={12} md={6} className='pt-5 pb-5'>
          <h3>
            „Als Kind ist jeder ein Künstler. Die Schwierigkeit liegt darin, als
            Erwachsener einer zu bleiben.“
          </h3>
          <h4>- Pablo Picasso-</h4>
          <br></br>
          <h5>
            Ich bin Mia. Ich habe Design und Architektur studiert und liebe es,
            mit meiner kleinen Papeterie Kreativität und Nachhaltigkeit zu
            vereinen. Am meisten bereitet es mir Freude, wenn ich mit einer
            lustigen Grußkarte ein kleines Lächeln auf ein Gesicht zaubern kann!
          </h5>
        </Col>
      </Row>
    </Container>
  );
};

export default AboutSection;
