import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const HomepageLayout = ({ hero, children }) => {
  return (
    <>
      {hero}
      <Container>
        <Row>
          {/* Main Content */}
          <Col className='p-3'>{children}</Col>
        </Row>
      </Container>
    </>
  );
};

export default HomepageLayout;
