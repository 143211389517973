import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit';
import axiosInstance from '../axiosConfig'; // Adjust the path accordingly
import { REHYDRATE } from 'redux-persist/es/constants';

// reset Stored auth state
export const reinitializeAuth = createAsyncThunk(
  'user/reinitializeAuth',
  async (_, { dispatch }) => {
    const token = localStorage.getItem('authToken');
    if (token) {
      setAuthToken(token);
      // Dispatch fetchUser to re-fetch and set the user details based on the existing token
      const actionResult = await dispatch(fetchUser());
      if (!actionResult.payload || actionResult.error) {
        // If fetching user details fails (e.g., if the token is not valid)
        // Dispatching logout without awaiting as its consequences are not immediate concerns for reinitialization logic
        dispatch(logoutUser());
        // Optionally clear localStorage and any auth headers here as well if logout action does not handle it
      }
    }
  }
);

// Async thunk for fetching user data
export const fetchUser = createAsyncThunk(
  'user/fetchUser',
  async (dispatch, { rejectWithValue }) => {
    try {
      // Append a timestamp to the URL to prevent caching
      const response = await axiosInstance.get(
        `users/api/me/?t=${new Date().getTime()}`
      );
      return response.data; // Directly return user data
    } catch (error) {
      // Check if the error is due to an invalid auth token
      if (error.response && error.response.status === 401) {
        // Logic to delete the auth token, e.g., from localStorage
        dispatch(logoutUser());
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// Define an asynchronous thunk action for deleting the account
export const deleteAccount = createAsyncThunk(
  'user/deleteAccount',
  async (password, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post('users/api/delete-account/', {
        password
      });
      return response.data; // Assuming the API responds with some data on success
    } catch (error) {
      return rejectWithValue(error.response.data); // Assuming the API responds with error details
    }
  }
);

// Async thunk for updating user data
export const updateUser = createAsyncThunk(
  'user/updateUser',
  async (user, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put('users/api/me/', user);
      return response.data; // Assuming the API returns the updated user data
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Async thunk for signup
export const signup = createAsyncThunk(
  'user/signup',
  async ({ email, password, subscribeToNewsletter }, { rejectWithValue }) => {
    try {
      const signupResponse = await axiosInstance.post('users/api/signup/', {
        email,
        password,
        subscribeToNewsletter
      });

      // Hypothetical success check; adjust based on your API response structure
      if (signupResponse.status === 201 || signupResponse.data.success) {
        // Proceed to log the user in after successful signup
        const loginResponse = await axiosInstance.post('api/auth/login/', {
          username: email,
          password
        });
        const token = loginResponse.data.token;
        setAuthToken(token); // Set the authToken for Axios and localStorage

        // Optionally send confirmation email; you might want to handle this differently based on your app's flow
        await axiosInstance.post(
          'users/api/send-confirmation-email/',
          {},
          {
            headers: { Authorization: `Token ${token}` }
          }
        );

        return { user: loginResponse.data.user, token };
      } else {
        // Handle non-successful signup
        return rejectWithValue('Signup was not successful.');
      }
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Async thunk for login
export const login = createAsyncThunk(
  'user/login',
  async ({ username, password }, { dispatch, rejectWithValue }) => {
    try {
      const response = await axiosInstance.post('api/auth/login/', {
        username,
        password
      });
      console.log(response.data.token);
      const token = response.data.token;
      setAuthToken(token); // Set the authToken for Axios and localStorage

      // This would be a good place to dispatch fetchUser if we wanted to automatically fetch user details right after login.
      // However, since fetchUser is its own async thunk, it should be dispatched from the component upon successful login.
      // Example: dispatch(fetchUser());

      // Just return the token as the action payload.
      // Assuming the UI or another process will handle fetching user details where appropriate.
      return { token };
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : 'An error occurred'
      );
    }
  }
);

// Set or delete the authToken in both Axios and localStorage
const setAuthToken = (token) => {
  if (token) {
    axiosInstance.defaults.headers.common['Authorization'] = `Token ${token}`;
    localStorage.setItem('authToken', token);
  }
};

// Define the async thunk for changing the password
export const changePassword = createAsyncThunk(
  'user/changePassword',
  async ({ oldPassword, newPassword }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post('users/api/change-password/', {
        old_password: oldPassword,
        new_password: newPassword
      });
      // Assume the response body contains a success message
      return response.data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      // Use rejectWithValue to return a custom error message
      return rejectWithValue(error.response.data);
    }
  }
);

export const logoutUser = createAsyncThunk('user/logout', async () => {
  try {
    // Attempt to notify the server about the logout
    const response = await axiosInstance.get('api/auth/logout');
    return response.data; // Successfully logged out from the server
  } catch (error) {
    console.info('Error during server-side logout. Client side-logout only.');
    // Optionally, use rejectWithValue to handle this as a failed state
    // return rejectWithValue('Error removing token from server.');
  } finally {
    // Ensure client-side cleanup happens regardless of server response
    // Remove the token from localStorage
    localStorage.removeItem('authToken');

    // Remove the Authorization header from future Axios requests
    delete axiosInstance.defaults.headers.common['Authorization'];
  }
});
// User slice
const userSlice = createSlice({
  name: 'user',
  initialState: {
    user: null,
    loggedIn: false,
    status: 'idle',
    error: null
  },
  reducers: {
    updateSubscriptionStatus: (state, action) => {
      if (state.user) {
        state.user.is_subscribed = action.payload; // Only update the isSubscribed property
      }
    }
  },
  extraReducers: (builder) => {
    builder
      // Handle fetchUser async thunk
      .addCase(fetchUser.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchUser.fulfilled, (state, action) => {
        state.user = action.payload; // Update state with fetched user data
        state.loggedIn = true; // Assuming fetching user data successfully means the user is logged in
        state.status = 'succeeded';
        state.error = null;
      })
      .addCase(fetchUser.rejected, (state, action) => {
        state.error = action.payload;
        state.loggedIn = false; // User is not logged in if fetching user data fails
        state.status = 'failed';
        state.user = null;
      })
      // Handle updateUser thunk
      .addCase(updateUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        const is_subscribed = state.user.is_subscribed;
        state.loading = false;
        state.user = action.payload; // Update the user data in the state
        state.user.is_subscribed = is_subscribed;
        state.error = null;
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      //handle login
      .addCase(login.fulfilled, (state, action) => {
        //state.user = action.payload.user;
        state.loggedIn = true;
        state.status = 'succeeded';
        state.error = null;
      })
      .addCase(login.rejected, (state, action) => {
        state.error = action.payload;
        state.loggedIn = false;
        state.status = 'failed';
        state.user = null;
      })
      .addCase(logoutUser.fulfilled, (state, action) => {
        // Reset state to initial state upon successful logout
        state.user = null;
        state.loggedIn = false;
        state.status = 'idle';
        state.error = null;
      })
      .addCase(logoutUser.rejected, (state, action) => {
        state.error = action.payload || 'Unknown error'; // Set error state
      })
      .addCase(changePassword.pending, (state) => {
        state.isChangingPassword = true;
        state.changePasswordError = null;
        state.changePasswordSuccessMessage = null;
      })
      .addCase(changePassword.fulfilled, (state, action) => {
        state.isChangingPassword = false;
        state.changePasswordSuccessMessage = action.payload.message;
      })
      .addCase(changePassword.rejected, (state, action) => {
        state.isChangingPassword = false;
        state.changePasswordError = action.payload;
      })
      .addCase(signup.fulfilled, (state, action) => {
        state.user = action.payload.user;
        state.loggedIn = true;
        state.status = 'succeeded';
        state.error = null;
      })
      .addCase(signup.rejected, (state, action) => {
        state.error = action.payload;
        state.loggedIn = false;
        state.status = 'failed';
      })
      .addCase(deleteAccount.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(deleteAccount.fulfilled, (state) => {
        state.isLoading = false;
        state.user = null; // clear user info upon deletion
        // You might also want to trigger a logout or redirect to a login page
      })
      .addCase(deleteAccount.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload || 'Fehler beim Löschen des Accounts.';
      })
      .addCase(REHYDRATE, (state, action) => {
        // Check if there is incoming user data and the user was logged in
        const incoming = action.payload?.user;
        if (incoming?.loggedIn) {
          // Only apply the rehydrated user state if the condition is met
          state.user = incoming.user;
          state.state = incoming.state;
        } else {
          // Optional: explicitly reset the state or handle as necessary
          // if the user was not logged in or other conditions are not met
          state.user = null;
          state.state = 'loggedOut';
        }
      });
    // Additional cases as needed
  }
});

// Action to manually set user data
export const setUser = createAction('user/setUser');
export default userSlice.reducer;

export const { updateSubscriptionStatus } = userSlice.actions;
