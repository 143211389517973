import Cart from '../components/Cart';
import BaseLayout from '../layouts/BaseLayout';

function CartPage() {
  //TODO: only display if not logged in

  const content = <Cart />;

  return <BaseLayout children={content}></BaseLayout>;
}

export default CartPage;
