import SetNewPasswordForm from '../components/SetNewPasswordForm';
import BaseLayout from '../layouts/BaseLayout';

function SetNewPasswordPage() {
  //TODO: only display if not logged in
  const content = <SetNewPasswordForm></SetNewPasswordForm>;

  return <BaseLayout children={content}></BaseLayout>;
}

export default SetNewPasswordPage;
