// CookieConsentBanner.js
import React from 'react';
import CookieConsent from 'react-cookie-consent';
import allowCookies from '../utils/allowCookies';
import declineCookies from '../utils/declineCookies';

const CookieConsentBanner = () => {
  return (
    <CookieConsent
      location='bottom'
      buttonText='Akzeptieren'
      declineButtonText='Ablehnen'
      cookieName='cookieConsent'
      style={{ background: '#2B373B' }}
      buttonStyle={{ color: '#4e503b', fontSize: '15px' }}
      declineButtonStyle={{
        background: '#cbcdd1',
        color: '#4e503b',
        fontSize: '15px'
      }}
      onAccept={allowCookies}
      onDecline={declineCookies}
      enableDeclineButton
    >
      Diese Webseite verwendet Cookies um die Nutzererfahrung zu verbessern.
      Infos hierzu finden Sie in der{' '}
      <a href='/data-privacy#cookies'>Datenschutzerklärung</a>.
    </CookieConsent>
  );
};

export default CookieConsentBanner;
