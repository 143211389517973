import { Alert, Card, Col, Row } from 'react-bootstrap';
import ProfilePageLayout from '../layouts/ProfilePageLayout';
import { useSelector } from 'react-redux';
import UserUpdateForm from '../components/UserUpdateForm';
import DeleteAccount from '../components/DeleteAccount';
import ChangePasswordForm from '../components/ChangePasswordForm';
// Import your action
import ToggleNewsletter from '../components/ToggleNewsletter';
import SendConfirmationEmailButton from '../components/SendConfirmationEmailButton'; // Adjust the path as necessary

function ProfilePage() {
  // Accessing user and isLoggedIn from the Redux store
  const user = useSelector((state) => state.user.user);
  const loggedIn = useSelector((state) => state.user.loggedIn);

  let content;
  if (!loggedIn) {
    content = (
      <Alert className='mt-2' variant='danger'>
        Bitte loggen Sie sich ein, um ihr Profil zu sehen.
      </Alert>
    );
  } else if (user) {
    // Render DisplayTable component if user data is available
    content = (
      <>
        <h1>Ihr Profil:</h1>

        <Card className='mb-3'>
          <Card.Body>
            <Card.Text>
              <strong>Kundennummer:</strong> {user.customer_id}
            </Card.Text>
            <Card.Text>
              <strong>Email: </strong> {user.email}
            </Card.Text>

            {!user.is_email_confirmed ? (
              <span style={{ color: 'orange' }}>
                Bitte bestätigen Sie Ihre E-Mail-Adresse.{' '}
                <SendConfirmationEmailButton />
              </span>
            ) : (
              <></>
            )}
          </Card.Body>
        </Card>
        <h5>Persönliche Informationen</h5>
        <UserUpdateForm initialUser={user}></UserUpdateForm>
        <Row>
          <Col className='mt-2'>
            <h5>Newsletter</h5>
            <ToggleNewsletter></ToggleNewsletter>
          </Col>
        </Row>
        <Row>
          <Col className='mt-2'>
            <h5>Passwort ändern</h5>
            <ChangePasswordForm></ChangePasswordForm>
          </Col>
        </Row>
        <Row>
          <Col className='mt-2'>
            <h5>Account löschen</h5>
            <DeleteAccount></DeleteAccount>
          </Col>
        </Row>
      </>
    );
  }

  return <ProfilePageLayout children={content}></ProfilePageLayout>;
}

export default ProfilePage;
